<template>
  <div class="studio">
    <PageTitle :title="studio.title | getTitle" :text="studio.description" />
    <section>
      <div class="row">
        <Sidebar>
          <div class="row">
            <div class="column small-12 medium-6 large-12">
              <div class="sidebar-block">
                <address v-if="studio.address_line_1">
                  {{ studio.address_line_1 }}<br />
                  {{ studio.address_line_2 }}
                </address>
                <ArrowLink
                  v-if="studio.google_maps__url"
                  :href="studio.google_maps__url"
                  text="View Map"
                />
              </div>
              <div class="sidebar-block">
                <template v-if="studio.phone_number">
                  <a
                    :href="`tel:${studio.phone_number}`"
                    class="phone"
                    >{{ studio.phone_number }}</a
                  >
                  <br />
                </template>
                <template v-if="studio.text_phone_number">
                  Text:
                  <a
                    :href="`tel:${studio.text_phone_number}`"
                    class="phone"
                    >{{ studio.text_phone_number }}</a
                  >
                  <br />
                </template>
                <ArrowLink
                  v-if="studio.email"
                  :href="`mailto:${studio.email}`"
                  :text="studio.email"
                />
              </div>
              <div v-if="servicesLink" class="sidebar-block">
                <ArrowLink :to="servicesLink" text="View Services" />
              </div>
            </div>
            <div class="column small-12 medium-6 large-12">
              <div v-if="studio.hours" class="sidebar-block">
                <h2>Hours</h2>
                <table>
                  <tbody>
                    <tr v-for="item in studio.hours">
                      <th>{{ item.days }}</th>
                      <td>{{ item.start_time }} - {{ item.end_time }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="sidebar-block">
                <ArrowLink :to="servicesLink" text="View Services" />
              </div>
            </div>
          </div>
        </Sidebar>
        <Main>
          <PrismicLazyImage
            v-if="studio.featured_image"
            :image="studio.featured_image"
            width="1465"
            :disableLazy="true"
          />
          <div v-if="studio.content" class="studio__body wysiwyg">
            <prismic-rich-text :field="studio.content" />
            <slice-zone v-if="studio.body && studio.body.length" type="studio" :uid="studioUid" />
          </div>
        </Main>
      </div>
    </section>
  </div>
</template>

<script>
import SliceZone from "vue-slicezone";

import { fetchStudioByUID } from "@/prismic/queries";

import metaMixin from "@/mixins/metaMixin";

import PageTitle from "@/components/global/PageTitle";
import Sidebar from "@/components/pages/studio/Sidebar";
import Main from "@/components/pages/studio/Main";
import ArrowLink from "@/components/global/ArrowLink";
import PrismicLazyImage from "@/components/prismic/PrismicLazyImage";

export default {
  name: "Studio",

  mixins: [metaMixin],

  components: {
    SliceZone,
    PageTitle,
    Sidebar,
    Main,
    ArrowLink,
    PrismicLazyImage
  },

  data() {
    return {
      studio: null
    };
  },

  props: {
    studioUid: {
      type: String,
      default: null
    }
  },

  computed: {
    servicesLink() {
      if (this.studio.services) {
        const firstServiceCategory = this.studio.services[0].service_category;

        if (firstServiceCategory) {
          return `/studio/${this.studioUid}/${firstServiceCategory._meta.uid}`;
        }
      }
    }
  },

  async asyncData({ app, params, error }) {
    try {
      const { data } = await app.apolloProvider.defaultClient.query({
        query: fetchStudioByUID,
        variables: {
          uid: params.studioUid
        }
      });

      const response = data.allStudios.edges;

      if (response.length) {
        const studio = response[0].node;

        app.store.dispatch("setMetaInfo", {
          meta: studio,
          title: studio.title[0].text + " Studio",
          description: studio.content,
          image: studio.featured_image,
          $liveChat: app.$liveChat
        });

        return {
          studio: studio
        };
      } else {
        error({ statusCode: 404, message: "This page could not be found" });
      }
    } catch (err) {
      console.log(err);
      error({ statusCode: 500, message: "An error has occured" });
    }
  },

  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Beauty Salon",
      name: 'Gee Beauty',
      url: this.url,
      photo: this.metaImage,
      description: this.metaDescription,
      email: this.studio.email,
      telephone: this.studio.telephone,
      address: `${this.studio.address_line_1} ${this.studio.address_line_2}`,
      openingHours: this.openingHours(),
      location: this.title
    };
  }
};
</script>
