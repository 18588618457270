export default function(doc) {
  if(!doc) return '/not-found';

  if (doc.isBroken) {
    return '/not-found';
  }

  if (doc.type === 'homepage') {
    return '/';
  }

  if (doc.type === 'page') {
    return '/pages/' + doc.uid;
  }

  if (doc.type === 'about') {
    return '/pages/about';
  }

  if (doc.type === 'custom_landing') {
    return '/content/' + doc.uid;
  }

  if (doc.type === 'blog_landing') {
    return '/beauty-edit';
  }

  if (doc.type === 'studio') {
    return '/studio/' + doc.uid;
  }

  if (doc.type === 'service_category') {
    return '/studio/toronto/' + doc.uid;
  }

  if (doc.type === 'shop_landing') {
    return '/shop';
  }

  if (doc.type === 'shop_category') {
    return '/shop/' + doc.uid;
  }

  if (doc.type === 'product_type' && doc.data) {
    const category = doc.data['shop-category'].uid;
    return '/shop/' + category + '/' + doc.uid;
  }

  if (doc.type === 'collection') {
    return '/shop/collections/' + doc.uid;
  }

  if (doc.type === 'brand') {
    return '/shop/brands/' + doc.uid;
  }

  if (doc.type === 'brand_landing') {
    return '/shop/brands';
  }

  if (doc.type === 'custom_brand_landing') {
    return '/shop/brand-collection/' + doc.uid;
  }

  if (doc.type === 'concern') {
    return '/shop/concerns/' + doc.uid;
  }

  if (doc.type === 'concern_landing') {
    return '/shop/concerns';
  }

  if (doc.type === 'product') {
    return '/shop/product/' + doc.uid;
  }

  if (doc.type === 'article') {
    return '/beauty-edit/' + doc.uid;
  }

  if (doc.type === 'blog_category') {
    return '/beauty-edit/category/' + doc.uid;
  }

  if (doc.type === 'author') {
    return '/beauty-edit/author/' + doc.uid;
  }

  if (doc.type === 'beauty_marks') {
    return '/beauty-marks';
  }

  return '/not-found';
}
