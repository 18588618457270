<template>
  <div v-if="concern" class="concern-single">
    <section class="page-title">
      <div class="row">
        <div class="column">
          <h1>
            The
            <strong>{{ concern.title | getTitle }}</strong>
            Edit
          </h1>
          <p v-if="concern.description">{{ concern.description }}</p>
        </div>
      </div>
    </section>

    <section class="concern-single__hero">
      <div class="row">
        <div class="column hero-main">
          <PrismicLazyImage
            v-if="concern.featured_image"
            :image="concern.featured_image"
            width="960"
            height="630"
            :disableLazy="true"
          />

          <h4 v-if="concern.subtitle">{{ concern.subtitle }}</h4>

          <div v-if="concern.content" class="wysiwyg">
            <MoreLessText>
              <template v-slot:text>
                <prismic-rich-text :field="concern.content" />
              </template>
              <template v-if="concern.more_content" v-slot:moreText>
                <prismic-rich-text :field="concern.more_content" />
              </template>
            </MoreLessText>
          </div>
        </div>

        <div v-if="primaryFeatures" class="column hero-side">
          <ConcernCard
            v-for="item in primaryFeatures"
            :key="item._meta && item._meta.id"
            :item="item" />
        </div>
      </div>
    </section>

    <Collection
      v-if="concern.featured_products"
      :subtitle="`Top <strong>${getTitle(concern.title)}</strong> Picks`"
      :description="concern.featured_products_description"
      :products="concern.featured_products" />

    <section v-if="secondaryFeatures.length" class="concern-single__feature">
      <div class="row">
        <div
          v-for="(item, i) in secondaryFeatures"
          :key="i"
          class="column small-12 large-6 xlarge-3">
          <ConcernCard
            :key="item._meta && item._meta.id"
            :item="item"
            isPortrait />
        </div>

        <Callout
          v-if="concern.callout"
          :type="concern.callout"
          :collection="concern.callout_collection" />
      </div>
    </section>

    <template v-if="featuredCollections.length">
      <Collection
        v-for="item in featuredCollections"
        :key="item._meta && item._meta.id"
        :subtitle="`<span>${getTitle(item.title)}</span>`"
        :description="item.description"
        :to="`/shop/collections/${item._meta && item._meta.uid}`"
        :products="findProductsByCollectionUid(item._meta && item._meta.uid)" />
    </template>

    <section v-if="similarConcerns.length" class="concern-single__similar">
      <div class="row">
        <div class="column"><h5>Similar Concerns</h5></div>
      </div>
      <LandingItem
        v-for="(item, i) in similarConcerns"
        :key="i"
        :item="item"
        type="concern" />
    </section>
  </div>
</template>

<script>
  import {
    fetchConcernByUID,
    fetchCollectionProductsByUIDs,
  } from '@/prismic/queries'

  import { getTitle } from '@/helpers'

  import metaMixin from '@/mixins/metaMixin'

  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage'
  import MoreLessText from '@/components/global/MoreLessText'
  import ConcernCard from '@/components/pages/concern-single/ConcernCard'
  import LandingItem from '@/components/shop/LandingItem'
  import Collection from '@/components/shop/Collection'
  import Callout from '@/components/global/Callout'

  export default {
    name: 'ConcernSingle',

    mixins: [metaMixin],

    components: {
      PrismicLazyImage,
      MoreLessText,
      ConcernCard,
      LandingItem,
      Collection,
      Callout,
    },

    data() {
      return {
        concern: null,
        collectionProducts: null,
      }
    },

    computed: {
      primaryFeatures() {
        if (!this.concern.features) return null

        return this.concern.features
          .map(({ feature }) => feature)
          .filter(v => v !== null)
          .slice(0, 3)
      },

      secondaryFeatures() {
        if (!this.concern.features) return null

        return this.concern.features
          .map(({ feature }) => feature)
          .filter(v => v !== null)
          .slice(3, 7)
      },

      featuredCollections() {
        if (!this.concern.featured_collections) return null

        return this.concern.featured_collections
          .map(({ collection }) => collection)
          .filter(v => v !== null)
      },

      similarConcerns() {
        if (!this.concern.similar_concerns) return null

        return this.concern.similar_concerns
          .map(({ concern }) => concern)
          .filter(v => v !== null)
      },
    },

    methods: {
      getTitle,

      findProductsByCollectionUid(uid) {
        if (!this.collectionProducts) return null

        return this.collectionProducts.find(product => product._meta.uid == uid)
          .products
      },
    },

    mounted() {
      this.$pinterest.pageVisit()
    },

    async asyncData({ app, params, error }) {
      try {
        const { data: concerns } = await app.apolloProvider.defaultClient.query(
          {
            query: fetchConcernByUID,
            variables: {
              uid: params.concernUid,
            },
          }
        )

        const concernResponse = concerns.allConcerns.edges

        if (concernResponse.length) {
          const concern = concernResponse[0].node

          app.store.dispatch('setMetaInfo', {
            meta: concern,
            title: `The ${concern.title[0].text} Edit`,
            description: concern.description,
            image: concern.featured_image,
            $liveChat: app.$liveChat,
          })

          const collectionUids = concern.featured_collections
            .filter(item => item.collection)
            .map(({ collection }) => {
              return collection._meta.uid
            })

          if (!collectionUids.length) {
            return { concern, collectionProducts: null }
          }

          const { data: collections } =
            await app.apolloProvider.defaultClient.query({
              query: fetchCollectionProductsByUIDs,
              variables: {
                uids: collectionUids,
              },
            })

          const collectionProductsResponse = collections.allCollections.edges
          const products = collectionProductsResponse.map(item => item.node)

          return {
            concern,
            collectionProducts: products.length ? products : null,
          }
        } else {
          error({ statusCode: 404, message: 'This page could not be found' })
        }
      } catch (err) {
        console.log(err)
        error({ statusCode: 500, message: 'An error has occured' })
      }
    },
  }
</script>
