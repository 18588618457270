<template>
  <PageTitle title="Loading Prismic Preview..." />
</template>

<script>
  import PageTitle from '@/components/global/PageTitle';

  export default {
    name: 'Preview',

    components: {
      PageTitle
    },

    mounted() {
      this.$prismic.preview();
    }
  }
</script>
