<template>
  <div v-if="page" class="blog-landing">
    <Top
      title="Beauty <span>Edit</span>"
      :description="page.description"
      :categories="featuredCategories"
      :authors="authors"
      :currentCategory="currentCategory"
      :currentAuthor="currentAuthor"
    />
    <Articles
      :currentCategory="currentCategory"
      :currentAuthor="currentAuthor"
    />
  </div>
</template>

<script>
import {
  fetchBlogLandingData,
  fetchAllBlogCategories,
  fetchAllAuthors
} from "@/prismic/queries";

import metaMixin from "@/mixins/metaMixin";

import Top from "@/components/pages/blog-landing/Top";
import Articles from "@/components/pages/blog-landing/Articles";

export default {
  name: "Blog",

  mixins: [metaMixin],

  components: {
    Top,
    Articles
  },

  data() {
    return {
      page: null,
      featuredCategories: null,
      allCategories: null,
      authors: null
    };
  },

  props: {
    categoryUid: {
      type: String,
      default: null
    },
    authorUid: {
      type: String,
      default: null
    }
  },

  computed: {
    currentCategory() {
      return this.allCategories.find(category => {
        return category._meta.uid == this.categoryUid;
      });
    },
    currentAuthor() {
      return this.authors.find(author => {
        return author._meta.uid == this.authorUid;
      });
    }
  },

  async asyncData({ app, params, error }) {
    try {
      const { data: data1 } = await app.apolloProvider.defaultClient.query({
        query: fetchBlogLandingData
      });

      const pageResponse = data1.allBlog_landings.edges;

      const { data: data2 } = await app.apolloProvider.defaultClient.query({
        query: fetchAllAuthors
      });

      const authorsResponse = data2.allAuthors.edges;

      const { data: data3 } = await app.apolloProvider.defaultClient.query({
        query: fetchAllBlogCategories
      });

      const categoriesResponse = data3.allBlog_categorys.edges;

      if (pageResponse.length) {
        const page = pageResponse[0].node;

        app.store.dispatch("setMetaInfo", {
          meta: page,
          title: "Beauty Edit",
          description: page.description,
          $liveChat: app.$liveChat
        });

        return {
          page: page,
          featuredCategories: page.featured_categories.map(item => item.category),
          allCategories: categoriesResponse.map(item => item.node),
          authors: authorsResponse.map(item => item.node)
        };
      } else {
        error({ statusCode: 404, message: "This page could not be found" });
      }
    } catch (err) {
      console.log(err);
      error({ statusCode: 500, message: "An error has occured" });
    }
  }
};
</script>
