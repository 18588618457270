import PrismicDOM from 'prismic-dom';
import linkResolver from './linkResolver';

const Elements = PrismicDOM.RichText.Elements;

export default function (type, element, content, children) {
  switch (type) {
    case Elements.hyperlink:
      const url = PrismicDOM.Link.url(element.data, linkResolver)
      if (element.data.link_type === 'Document') {
        return `<a href="${url}" data-nuxt-link>${content}</a>`;
      } else {
        const target = element.data.target
          ? `target="${element.data.target}" rel="noopener"`
          : '';
        return `<a href="${url}" ${target}>${content}</a>`;
      }

    case Elements.image:
      let image = `<img src="${
        element.url
      }" alt="${element.alt || ''}">`;

      if (element.linkTo) {
        const url = PrismicDOM.Link.url(element.linkTo, linkResolver);

        if (element.linkTo.link_type === 'Document') {
          return `<a href="${url}" data-nuxt-link>${image}</a>`;
        } else {
          const target = element.linkTo.target
            ? `target="${element.linkTo.target}" rel="noopener"`
            : '';
          return `<a href="${url}" ${target}>${image}</a>`;
        }
      }
      return image;

    case Elements.heading1:
      return `<h1>${children.join('')}</h1>`;

    case Elements.heading2:
      return `<h2>${children.join('')}</h2>`;

    case Elements.heading3:
      return `<h3>${children.join('')}</h3>`;

    case Elements.heading4:
      return `<h4>${children.join('')}</h4>`;

    case Elements.heading5:
      return `<h5>${children.join('')}</h5>`;

    case Elements.heading6:
      return `<h6>${children.join('')}</h6>`;

    case Elements.paragraph:
      return `<p>${children.join('')}</p>`;

    case Elements.preformatted:
      return `<pre>${children.join('')}</pre>`;

    case Elements.strong:
      return `<strong>${children.join('')}</strong>`;

    case Elements.em:
      return `<em>${children.join('')}</em>`;

    case Elements.listItem:
      return `<li>${children.join('')}</li>`;

    case Elements.oListItem:
      return `<li>${children.join('')}</li>`;

    case Elements.list:
      return `<ul>${children.join('')}</ul>`;;

    case Elements.oList:
      return `<ol>${children.join('')}</ol>`;

    case Elements.embed:
      return `
        <div
          class="embed"
          data-oembed="${element.oembed.embed_url}"
          data-oembed-type="${element.oembed.type}"
          data-oembed-provider="${element.oembed.provider_name}"
        >
          ${element.oembed.html}
        </div>
      `;

    case Elements.label:
      const label = element.data.label ? ` class="${element.data.label}"` : '';
      return `<span ${label}>${children.join('')}</span>`;

    case Elements.span:
      return content ? content.replace(/\n/g, '<br />') : '';

    default:
      return null;
  }
}
