<template>
<div class="form-page">
  <PageTitle title="Request An Appointment" text="While we await the Ontario government's Phase 3 commencement date and details, our Front Desk Team is building out a detailed waitlist so we can book your appointment, when the time comes, as smoothly as possible. Please fill out the information below for your Gee Beauty appointment request. Your request will then be submitted to our waitlist. Once Phase 3 begins, our Front Desk Team will personally contact you to book your requested appointment at our soonest availability. Please note: we will first be honouring appointment requests from those with pre-booked Gee Beauty appointments between November 23rd-December 30th 2020. Due to health and safety measures in protection of our clients and team, there may be a longer wait than usual as we practice social distancing and limited occupancy. We are committed to doing our very best to service you safely. We appreciate your support and patience." />
  <section>
    <div class="row">
      <div class="column small-12 medium-10 large-8 xlarge-6 xxlarge-4">
        <form class="form" :class="{ 'has-errors': $v.$anyError }" @submit.prevent="handleSubmit">
          <div class="form__row">
            <div class="form__field" :class="{ 'has-error': $v.name.$error }">
              <label for="name">Name</label>
              <input id="name" name="name" type="text" v-model.trim="name" />
              <span class="error" v-if="$v.name.$error && !$v.name.required">This field is required</span>
            </div>
          </div>
          <div class="form__row">
            <div class="form__field form__field--half" :class="{ 'has-error': $v.email.$error }">
              <label for="email">Email</label>
              <input id="email" name="email" type="email" v-model.trim="email" />
              <span class="error" v-if="$v.email.$error && !$v.email.required">This field is required</span>
              <span class="error" v-if="$v.email.$error && !$v.email.email">Please enter a valid email address</span>
            </div>
            <div class="form__field form__field--half" :class="{ 'has-error': $v.phone.$error }">
              <label for="phone">Phone Number</label>
              <input id="phone" name="phone" type="text" v-model.trim="phone" />
              <span class="error" v-if="$v.phone.$error && !$v.phone.required">This field is required</span>
            </div>
          </div>
          <div class="form__row">
            <div class="form__field form__field--half" :class="{ 'has-error': $v.date.$error }">
              <label for="date">Date</label>
              <input id="date" name="date" type="date" v-model.trim="date" />
              <span class="error" v-if="$v.date.$error && !$v.date.required">This field is required</span>
              <span class="error" v-if="$v.date.$error && !$v.date.minValue">Please choose an upcoming date</span>
            </div>
            <div class="form__field form__field--half" :class="{ 'has-error': $v.time.$error }">
              <label for="time">Ideal Time of Day</label>
              <div class="select">
                <select id="time" v-model="time">
                  <option v-for="option in times" :value="option">{{ option }}</option>
                </select>
              </div>
              <span class="error" v-if="$v.time.$error && !$v.time.required">This field is required</span>
            </div>
          </div>
          <div class="form__row">
            <div class="form__field">
              <label for="service">Type Of Service</label>
              <div class="select">
                <select id="service" v-model="time">
                  <option v-for="option in services" :value="option">{{ option }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form__row">
            <div class="form__field">
              <label for="provider">Preferred Service Provider</label>
              <div class="select">
                <select id="provider" v-model="provider">
                  <option v-for="option in providers" :value="option">{{ option }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form__row">
            <div class="form__field">
              <label for="comments">Notes/Comments</label>
              <textarea id="comments" v-model="comments" />
            </div>
          </div>
          <div class="form__row form__row--button">
            <div class="form__field">
              <Button type="submit" :text="buttonText" :disabled="isSubmitting" />
              <FormFeedback :showFeedback="showFeedback" :submitState="submitState" :submitMessage="submitMessage" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import { mapState } from "vuex";

import { required, email, minValue } from "vuelidate/lib/validators";

import formMixin from "@/mixins/formMixin";
import metaMixin from "@/mixins/metaMixin";

import PageTitle from "@/components/global/PageTitle";
import Button from "@/components/global/Button";
import FormFeedback from "@/components/global/FormFeedback";

export default {
  name: "RequestAppointment",

  mixins: [formMixin, metaMixin],

  components: {
    PageTitle,
    Button,
    FormFeedback
  },

  data() {
    return {
      email: null,
      name: null,
      phone: null,
      date: null,
      time: null,
      service: null,
      provider: null,
      comments: null,
      times: [
        '9am - Noon',
        'Noon - 2pm',
        '2pm - 4pm',
        '4pm - 6pm',
        '6pm - 8pm',
        'I\'m Flexible'
      ],
      services: [
        'Service 1',
        'Service 2',
        'Service 3'
      ],
      providers: [
        'Provider 1',
        'Provider 2',
        'Provider 3'
      ],
      defaultButtonText: "Request Appointment"
    }
  },

  computed: {
    ...mapState({
      locale: state => state.locale,
      userData: state => state.user.data
    }),
  },

  validations: {
    email: {
      required,
      email
    },
    name: {
      required
    },
    phone: {
      required
    },
    date: {
      required,
      minValue(val) {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        return new Date(val) >= yesterday;
      }
    },
    time: {
      required
    }
  },

  methods: {
    async handleSubmit() {
      this.$v.$touch();
      this.showFeedback = false;
      this.isSubmitting = true;

      if (this.$v.$invalid) {
        this.handleFormFeedback("error");
      } else {
        this.handleFormFeedback("success", "Thank you gorgeous! We will be in touch.");
      }
    }
  },

  asyncData({ app, params, error }) {
    app.store.dispatch("setMetaInfo", {
      title: "Request An Appointment",
      $liveChat: app.$liveChat
    });
  },

  fetch() {
    if(this.userData) {
      this.name = this.userData.firstName + (this.userData.lastName ? ' ' + this.userData.lastName : '');
      this.email = this.userData.email;
    }
  }
};
</script>
