<template>
  <div v-if="model || type === 'search'" class="shop-landing">
    <LandingTop
      :model="model"
      :title="type === 'search' ? handle : title"
      :shopCategory="shopCategory"
      :breadcrumbs="breadcrumbs"
      :children="children"
      :siblings="siblings" />

    <LandingProducts
      :callout="callout"
      :calloutArticle="calloutArticle"
      :calloutProduct="calloutProduct"
      :isWideCallout="isWideCallout"
      :numFirstProducts="numFirstProducts"
      :products="products"
      :isLoading="isLoading"
      :hasMoreProducts="hasMoreProducts"
      :activeFilters="selections"
      :title="!isLoading && model ? $prismic.asText(model.node.title) : title"
      @loadMore="loadMore">
      <template #filters>
        <LandingFilters
          :filters="filters"
          :activeFilters="selections"
          :defaultSortBy="defaultSortBy"
          @updateFilters="handleFilterChange($event)" />
      </template>
    </LandingProducts>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import {
    fetchProducts,
    fetchLandingPageData,
    explodeVariants,
  } from '@/services/shopLandingService'

  import {
    buildFilters,
    appendParams,
    filterProducts,
  } from '@/services/LandingPageFiltersService'

  import {
    findAncestor,
    findCollectionSiblings,
  } from '@/services/collectionService'

  import metaMixin from '@/mixins/metaMixin'

  import { removeOtherLocaleProducts } from '@/helpers'

  import { sortProducts } from '@/services/SortService'

  import LandingTop from '@/components/shop/LandingTop'
  import LandingFilters from '@/components/pages/shop-landing/LandingFilters'
  import LandingProducts from '@/components/pages/shop-landing/LandingProducts'

  export default {
    name: 'ShopLanding',

    mixins: [metaMixin],

    components: {
      LandingTop,
      LandingProducts,
      LandingFilters,
    },

    data() {
      return {
        title: 'Shop',
        model: null,
        handle: null,
        type: null,
        shopCategory: null,
        productQuery: null,
        originalProducts: [],
        products: [],
        nextProducts: [],
        allProducts: [],
        totalCount: 0,
        pageInfo: {},
        endCursor: '',
        isLoading: true,
        isInitial: true,
        breadcrumbs: null,
        collectionKeys: [
          'highlights',
          ['productTypes', 'featured_collections'],
        ],
        children: null,
        siblings: null,
        selections: {
          sortBy: null,
          product_types: [],
          brands: [],
          concerns: [],
          highlights: [],
        },
        filters: [],
        page: 1,
        showProductVariants: false,
      }
    },

    provide() {
      return {
        showProductVariants: this.showProductVariants,
      }
    },

    computed: {
      ...mapState({
        shopLinks: state => state.header.links.shop,
        locale: state => state.locale,
      }),

      numFirstProducts() {
        return this.isWideCallout ? 4 : 5
      },

      callout() {
        if (this.isFiltered) return null

        return this.model ? this.model.node.callout : null
      },

      calloutArticle() {
        return this.model ? this.model.node.callout_article : null
      },

      calloutProduct() {
        if (this.isFiltered) return null

        return this.model ? this.model.node.callout_product : null
      },

      isFiltered() {
        return Object.keys(this.selections).some(el => {
          if (el === 'sortBy') return false

          if (this.selections[el].length) return true
        })
      },

      isWideCallout() {
        if (this.callout) {
          return ['Product', 'Article'].includes(this.callout)
        }
      },

      numResults() {
        if (!this.isInitial) return 12

        return this.callout ? (this.isWideCallout ? 10 : 11) : 12
      },

      resultsToShow() {
        return this.numResults * this.page
      },

      shopCatData() {
        if ('shop' === this.type) return null

        if (!this.shopCategory && this.type === 'collections') {
          const ancestor = this.getCollectionAncestors(
            this.model.node._meta.uid
          )

          if (!ancestor) return null

          return !ancestor.shopCategory
            ? ancestor
            : this.shopLinks.filter(
                ({ _meta }) => _meta.uid === ancestor.shopCategory._meta.uid
              )[0]
        }

        return this.shopLinks.filter(
          shopCat => this.shopCategory === shopCat._meta.uid
        )[0]
      },

      hasMoreProducts() {
        return this.nextProducts && this.nextProducts.length > 0
      },
    },

    methods: {
      async initPage() {
        this.page = parseInt(this.$route.query.page ?? this.page)
        this.defaultSortBy = this.$route.query.sortBy ?? 'relevance'
        this.selections.sortBy = this.defaultSortBy

        this.setPageParam()

        await this.getProducts()

        this.siblings = this.findSiblings()
        this.children = this.findChildren()
        this.breadcrumbs = this.buildBreadCrumbs()

        if(this.model.node.__typename == 'Collection' && this.model.node.show_only_on_ca && this.locale == "en-us")
          this.$router.push({ path: '/' })

      },

      async getProducts() {
        const { edges, pageInfo, totalCount, cursor } =
          await this.handleFetchingProducts()

        console.log('show: ' + this.showProductVariants)

        const products = this.showProductVariants
          ? explodeVariants(edges, this.$store.state.locale)
          : edges

        await this.filterAndSortProducts(products)

        this.pageInfo = pageInfo
        this.totalCount = totalCount
        this.endCursor = cursor

        this.isInitial = false
        this.isLoading = false
      },

      async filterAndSortProducts(products) {
        if (!products) {
          return null
        }

        this.allProducts = products

        const filteredProducts = await this.findLocalProducts(products)

        const filteredProductsNew = await filterProducts(this.selections, filteredProducts);

        var show_best_seller = false;
        this.selections.highlights.forEach(function(filter){
          if(filter.includes("best-sellers"))
            show_best_seller = true;
        });
        if(show_best_seller) {
          filteredProductsNew.forEach(function (product) {
            product.is_best_seller = true;
          });
        }

        const sortedProducts = sortProducts({
          products: filteredProductsNew,
          locale: this.locale,
          pageType: this.type,
          sortBy: this.selections.sortBy,
          sortCollectionManually:
            this.type === 'collection' &&
            this.model?.node.order_relevance_manually,
        })

        if (!sortedProducts) return null

        const newProducts = [...sortedProducts].slice(0, this.resultsToShow)

        this.products = newProducts
        this.nextProducts = sortedProducts.slice(this.resultsToShow)
        this.allProducts = products
      },

      async handleFetchingProducts() {
        try {
          // const { edges, totalCount, pageInfo } = await fetchProducts({
          const data = await fetchProducts({
            type: this.type,
            store: this.$store,
            handle: this.handle,
            numResults: this.numResults,
            query: this.productQuery,
            cursor: this.endCursor,
            hasNoProducts: this.model?.node?.has_no_products ?? false
          })

          let cursor = this.endCursor

          if (data.edges && data.edges.length) {
            cursor = [...data.edges].pop()?.cursor
          }

          const { edges, totalCount, pageInfo } = data

          return { edges, totalCount, pageInfo, cursor }
        } catch (err) {
          console.log(err)
          return this.$nuxt.error(err)
        }
      },

      findSiblings() {
        if (
          [
            'shopCategory',
            'brand',
            'brandsLanding',
            'concern',
            'concernsLanding',
            'shop',
            'search',
          ].includes(this.type)
        ) {
          return null
        }

        if (!this.model) return null

        const { _meta } = this.model.node

        if ('product_type' === this.type) {
          if (!this.shopCatData && !this.shopCatData.productTypes) return null

          return this.shopCatData.productTypes.filter(
            prodType => _meta.uid !== prodType._meta.uid
          )
        }

        if ('collections' === this.type) {
          if (!this.shopCatData) return null
          return findCollectionSiblings(
            _meta.uid,
            this.shopCatData,
            this.collectionKeys
          )
        }
      },

      findChildren() {
        if (['product_type', 'search', 'brand', 'shop'].includes(this.type)) {
          return null
        }

        const productType = this.shopCatData?.productTypes.filter(
          prodType => this.model.node._meta.uid === prodType._meta.uid
        )

        if (!productType || !productType.length) return null

        if (!productType[0].featured_collections) return null

        const children = productType[0].featured_collections
          .map(({ collection }) => collection)
          .filter(v => v !== null)

        return children.length ? children : null
      },

      handleFilterChange(newSelections) {
        this.selections = { ...newSelections }
        appendParams(this.selections)
      },

      async loadMore() {
        this.page = this.page + 1

        this.setPageParam()

        this.paginateProducts()
      },

      setPageParam() {
        appendParams({ page: this.page })
      },

      setPageScroll() {
        if (!process.client || this.page === 1) return null

        const el = document.querySelector('.shop-landing__products')

        if (el && this.products.length > 0) {
          setTimeout(() => {
            return window.scrollTo({
              top: el.scrollHeight,
              behavior: 'smooth',
            })
          }, 250)
        }

        return window.scrollTo(0, 0)
      },

      paginateProducts() {
        const index = this.isInitial ? this.resultsToShow : this.numResults

        const newProducts = [...this.nextProducts].slice(0, index)
        const nextProducts = [...this.nextProducts].slice(index)

        this.products = [...this.products, ...newProducts]
        this.nextProducts = nextProducts
      },

      /**
            buildBreadCrumbs()
            @returns Array

            depending on type generates different levels of breadcrumbs
            **/
      buildBreadCrumbs() {
        if (
          [
            'shopCategory',
            'brand',
            'brandsLanding',
            'concern',
            'concernsLanding',
            'shop',
            'search',
          ].includes(this.type)
        )
          return null

        if ('product_type' === this.type) {
          return [this.shopCatData]
        }

        if ('collections' === this.type) {
          const ancestor = this.getCollectionAncestors(
            this.model.node._meta.uid
          )

          if (!ancestor) return null

          if (!ancestor.shopCategory) return [ancestor]

          return [ancestor.shopCategory, ancestor]
        }

        return null
      },

      /**
            getCollectionAncestors
            @returns Object
            **/
      getCollectionAncestors(uid) {
        const ancestor = findAncestor(uid, this.shopLinks, this.collectionKeys)

        if (!ancestor) return null

        return ancestor
      },

      removeCalloutProduct(products) {
        if (!products) return []

        return products.filter(product => {
          const prod = this.type === 'collections' ? product : product.node

          if (!prod) return false

          return prod._meta.uid !== this.calloutProduct._meta.uid
        })
      },

      findLocalProducts(products) {
        const localProducts = removeOtherLocaleProducts(
          products,
          this.$store.state.locale
        )

        if (!this.calloutProduct) return localProducts

        if (localProducts.length < this.numFirstProducts) return localProducts

        return this.removeCalloutProduct(localProducts)
      },

      async createFilters() {
        this.filters = await buildFilters({
          products: this.allProducts,
          isMakeup: this.shopCategory === 'makeup',
          isProductType: this.type === 'product_type',
          shopCategory: this.shopCatData,
        })
      },

      handleTracking() {
        this.$pinterest.pageVisit()

        if (this.model) {
          this.$pinterest.viewCategory({
            id: this.model.id,
            title: this.model.title,
          })
        }
      },
    },

    watch: {
      page: function (newVal) {
        appendParams({ page: newVal })
      },

      $route: async function (newVal, oldVal) {
        if (newVal === oldVal) return false

        this.isLoading = true
        this.model = null
        this.products = []
        this.nextProducts = []
        this.totalCount = 0
        this.pageInfo = {}
        this.endCursor = ''
        this.filters = null
        this.isInitial = true
        this.showProductVariants = false

        const { type, model, handle, shopCategory, productQuery } =
          await fetchLandingPageData(this.$route.fullPath, this.locale)

        let metaModel = model
        if (type === 'search' && !metaModel) {
          metaModel = {
            node: {
              title: `Search: ${handle}`,
              description: `Gee Beauty search results for ${handle}`,
              image: null,
            },
          }
        }

        this.$store.dispatch('setMetaInfo', {
          meta: metaModel.node,
          title: metaModel.node.title,
          description: metaModel.node.description,
          image: metaModel.node.featured_image,
          $liveChat: this.$liveChat,
        })

        this.model = model
        this.handle = handle
        this.type = type
        this.shopCategory = shopCategory
        this.productQuery = productQuery
        this.showProductVariants =
          this.model?.node?.show_product_variants ?? false

        await this.initPage()

        this.createFilters()
        this.handleTracking()
      },

      selections: {
        async handler(newVal, oldVal) {
          if (newVal === oldVal) return false

          this.isInitial = true
          this.isLoading = true

          await this.filterAndSortProducts(this.allProducts)

          this.isLoading = false

        },
        deep: true,
      },
    },

    async created() {
      await this.initPage()
      this.createFilters()
      this.setPageScroll()
    },

    mounted() {
      this.handleTracking()
    },

    async asyncData({ app, route, store, error }) {
      try {
        // fetch the shop landing page meta details
        const { type, model, handle, shopCategory, productQuery } =
          await fetchLandingPageData(route.fullPath, store.state.locale)

        let metaModel = model
        if (type === 'search' && !metaModel) {
          metaModel = {
            node: {
              title: `Search: ${handle}`,
              description: `Gee Beauty search results for ${handle}`,
              image: null,
            },
          }
        }

        app.store.dispatch('setMetaInfo', {
          meta: metaModel.node,
          title: metaModel.node.title,
          description: metaModel.node.description,
          image: metaModel.node.featured_image,
          $liveChat: app.$liveChat,
        })

        return {
          model,
          handle,
          shopCategory,
          productQuery,
          type,
          showProductVariants: model?.node?.show_product_variants ?? false,
        }
      } catch (err) {
        if (err.statusCode === 404) return error(err)

        console.log(err)

        error({ statusCode: 500, message: 'An error has occured' })
      }
    },
  }
</script>
