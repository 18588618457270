import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/pages/Home'
import Studio from '@/pages/Studio'
import StudioServices from '@/pages/StudioServices'
import BlogLanding from '@/pages/BlogLanding'
import Article from '@/pages/Article'
import Regimen from '@/pages/Regimen'
import About from '@/pages/About'
import ProductSingle from '@/pages/ProductSingle'
import BrandLanding from '@/pages/BrandLanding'
import ConcernLanding from '@/pages/ConcernLanding'
import ConcernSingle from '@/pages/ConcernSingle'
import ShopLanding from '@/pages/ShopLanding'
import BeautyMarks from '@/pages/BeautyMarks'
import Account from '@/pages/Account'
import Profile from '@/components/pages/account/Profile'
import Addresses from '@/components/pages/account/Addresses'
import NewAddress from '@/components/pages/account/NewAddress'
import EditAddress from '@/components/pages/account/EditAddress'
import OrderHistory from '@/components/pages/account/OrderHistory'
import OrderDetail from '@/components/pages/account/OrderDetail'
import Subscriptions from '@/components/pages/account/Subscriptions'
import SubscriptionDetail from '@/components/pages/account/SubscriptionDetail'
import BeautyMarksAccount from '@/components/pages/account/BeautyMarks'
import PasswordReset from '@/pages/PasswordReset'
import ActivateAccount from '@/pages/ActivateAccount'
import Quiz from '@/pages/Quiz'
import CustomLanding from '@/pages/CustomLanding'
import CustomFeatureLanding from '@/pages/CustomFeatureLanding'
import Generic from '@/pages/Generic'
import RequestAppointment from '@/pages/RequestAppointment'
import Preview from '@/pages/Preview'

import smile from '@/middleware/smile'

Vue.use(Router)

export function createRouter() {
  const router = new Router({
    mode: 'history',
    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
        beforeEnter: smile,
      },
      {
        path: '/studio/:studioUid',
        name: 'studio',
        component: Studio,
        props: true,
      },
      {
        path: '/studio/:studioUid/:serviceCategoryUid',
        name: 'services',
        component: StudioServices,
        props: true,
      },
      {
        path: '/beauty-edit',
        name: 'beauty-edit',
        component: BlogLanding,
        props: true,
        children: [
          { path: 'category/:categoryUid' },
          { path: 'author/:authorUid' },
          { path: 'category/:categoryUid/author/:authorUid' },
        ],
      },
      {
        path: '/beauty-edit/:uid',
        name: 'article',
        component: Article,
        props: true,
      },
      {
        path: '/shop/product/:uid',
        name: 'product-single',
        component: ProductSingle,
        props: true,
      },
      {
        path: '/shop/concerns',
        name: 'concern-landing',
        component: ConcernLanding,
        props: true,
      },
      {
        path: '/shop/concerns/:concernUid',
        name: 'concern-single',
        component: ConcernSingle,
        props: true,
      },
      {
        path: '/shop/brands',
        name: 'brand-landing',
        component: BrandLanding,
        props: true,
      },
      {
        path: '/shop/brand-collection/:uid',
        name: 'custom-brand-landing',
        component: BrandLanding,
        props: true,
      },
      {
        path: '/shop',
        name: 'shop',
        component: ShopLanding,
        children: [
          { path: 'search' },
          { path: ':category/' },
          { path: ':category/:subCategory' },
        ],
      },
      {
        path: '/pages/about',
        name: 'about',
        component: About,
      },
      {
        path: '/beauty-marks',
        name: 'beauty-marks',
        component: BeautyMarks,
      },
      {
        path: '/account',
        component: Account,
        children: [
          {
            path: 'addresses',
            name: 'addresses',
            component: Addresses,
          },
          {
            path: 'addresses/new',
            name: 'new-address',
            component: NewAddress,
          },
          {
            path: 'addresses/:addressId',
            name: 'edit-address',
            component: EditAddress,
            props: true,
          },
          {
            path: 'orders',
            name: 'order-history',
            component: OrderHistory,
          },
          {
            path: 'orders/:orderId',
            name: 'order-detail',
            component: OrderDetail,
            props: true,
          },
          {
            path: 'beauty-marks',
            name: 'beauty-marks-account',
            component: BeautyMarksAccount,
            alias: '/beauty-marks',
          },
          {
            path: '',
            name: 'profile',
            component: Profile,
          },
          {
            path: 'subscriptions',
            name: 'subscriptions',
            component: Subscriptions,
          },
          {
            path: 'subscriptions/:subId',
            name: 'subscription-details',
            component: SubscriptionDetail,
          },
        ],
      },
      // {
      //   path: "/skincare-quiz",
      //   name: "skincare-quiz",
      //   component: Quiz
      // },
      // {
      //   path: "/regimen",
      //   name: "regimen",
      //   component: Regimen
      // },
      {
        path: '/password/reset/:shopify_id',
        name: 'password-reset',
        component: PasswordReset,
      },
      {
        path: '/account/activate/:shopify_id',
        name: 'activate-account',
        component: ActivateAccount,
      },
      {
        path: '/content/:uid',
        name: 'custom-landing',
        component: CustomLanding,
        props: true,
      },
      {
        path: '/feature/:uid',
        name: 'custom-feature-landing',
        component: CustomFeatureLanding,
        props: true,
      },
      // {
      //   path: "/pages/request-appointment",
      //   name: "request-appointment",
      //   component: RequestAppointment
      // },
      {
        path: '/pages/:uid',
        name: 'generic',
        component: Generic,
        props: true,
      },
      {
        path: '/preview',
        name: 'preview',
        component: Preview,
      },
    ],
    scrollBehavior: async (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition
      }

      const findEl = async (hash, x) => {
        return (
          document.querySelector(hash) ||
          new Promise((resolve, reject) => {
            if (x > 50) {
              return resolve()
            }
            setTimeout(() => {
              resolve(findEl(hash, ++x || 1))
            }, 100)
          })
        )
      }

      if (to.hash) {
        let el = await findEl(to.hash)

        if ('scrollBehavior' in document.documentElement.style) {
          return window.scrollTo({ top: el.offsetTop, behavior: 'smooth' })
        } else {
          return window.scrollTo(0, el.offsetTop)
        }
      }

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 250)
      })
    },
  })

  router.beforeEach(smile)

  return router
}
