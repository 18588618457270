<template>
  <div v-if="page" class="home">
    <h1 class="accessible">{{ page.title | getTitle }}</h1>

    <Hero
      v-if="page.show_hero"
      :slides="page.hero_slides"
    />

    <slice-zone
      type="custom_feature_landing"
      :uid="uid"
      body="body1"
      class="home__slicezone" />
  </div>
</template>

<script>
  import SliceZone from 'vue-slicezone'

  import { fetchCustomFeatureLandingByUID } from '@/prismic/queries'

  import metaMixin from '@/mixins/metaMixin'

  import Hero from '@/components/pages/home/Hero'

  export default {
    name: 'CustomFeatureLanding',

    mixins: [metaMixin],

    components: {
      Hero,
      SliceZone
    },

    data() {
      return {
        page: null,
      }
    },

    props: {
      uid: {
        type: String,
        default: null,
      },
    },

    mounted() {
      this.$pinterest.pageVisit()
    },

    async asyncData({ app, params, store, error }) {
      try {
        const { data } = await app.apolloProvider.defaultClient.query({
          query: fetchCustomFeatureLandingByUID,
          variables: {
            uid: params.uid,
          },
        })

        const { edges } = data.allCustom_feature_landings

        if (!edges.length) {
          return error({
            statusCode: 404,
            message: 'This page could not be found',
          })
        }

        const page = edges[0].node

        app.store.dispatch('setMetaInfo', {
          meta: page,
          title: page.title,
          $liveChat: app.$liveChat
        })

        return { page }
      } catch (err) {
        console.log(err)
        error({ statusCode: 500, message: 'An error has occured' })
      }
    },

    jsonld() {
      return {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        name: this.$config.siteName,
        url: this.url,
        image: this.metaImage,
        description: this.metaDescription,
      }
    },
  }
</script>
