<template>
  <div class="studio">
    <PageTitle
      title="Services"
      :accessibleTitle="`${studioTitle} ${serviceCategoryTitle} Services`"
      :text="studio.services_description"
    />
    <section>
      <div class="row">
        <Sidebar v-if="studio.services">
          <ul class="side-nav">
            <li v-for="(serviceCategoryItem, i) in serviceCategories" :key="i">
              <nuxt-link
                v-if="serviceCategoryItem"
                :to="`/studio/${studioUid}/${serviceCategoryItem._meta.uid}`"
                :class="{
                  'is-active':
                    serviceCategoryItem._meta.uid == serviceCategoryUid,
                }"
              >
                {{ serviceCategoryItem.title | getTitle }}
              </nuxt-link>
            </li>
          </ul>
        </Sidebar>
        <Main>
          <div v-if="serviceCategory.featured_image" class="image-header">
            <PrismicLazyImage
              :image="serviceCategory.featured_image"
              width="1465"
              height="485"
              :disableLazy="true"
            />
            <h2>{{ serviceCategoryTitle }}</h2>
          </div>
          <h2 v-else class="text-header">{{ serviceCategoryTitle }}</h2>
          <Service
            v-for="(service, index) in services"
            :key="index"
            :title="service.service_title | getTitle"
            :text="service.service_description"
            :time="service.service_length"
            :price="service.service_price"
            :studio="studio"
          />
          <!-- <h3 class="h2 h4">Facials</h3>
          <Service
            title="As You Like It"
            text="Tint + lift your lashes at once! This treatment is designed to give you longer-looking, darker & more lifted looking lashes with a beautiful curve upwards. Gorgeous results last 2-3 months. *Please note: 48 hours post Lash Lift, water must be avoided around the eye area to ensure the most desired result. This includes submerging your face in water, excess sweat, eye makeup + eye makeup remover. Your Lash specialist will review all post-care with you."
            time="90 Minutes"
            price="Price on Request"
          /> -->
          <div class="cta">
            <h2 class="h4">Questions About Our Services?</h2>
            <Button text="Contact Us" @click="openContactModal" />
          </div>
        </Main>
      </div>
    </section>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    fetchServiceCategoryByUID,
    fetchStudioByUID,
  } from '@/prismic/queries'

  import metaMixin from '@/mixins/metaMixin'

  import { getTitle, getAllServiceCategories } from '@/helpers'

  import PageTitle from '@/components/global/PageTitle'
  import Tooltip from '@/components/global/Tooltip'
  import Sidebar from '@/components/pages/studio/Sidebar'
  import Main from '@/components/pages/studio/Main'
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage'
  import Service from '@/components/pages/studio/Service'
  import Button from '@/components/global/Button'

  export default {
    name: 'StudioServices',

    mixins: [metaMixin],

    components: {
      PageTitle,
      Tooltip,
      Sidebar,
      Main,
      PrismicLazyImage,
      Service,
      Button,
    },

    data() {
      return {
        serviceCategory: null,
        studio: null,
      }
    },

    props: {
      studioUid: {
        type: String,
        default: null,
      },
      serviceCategoryUid: {
        type: String,
        default: null,
      },
    },

    computed: {
      studioTitle() {
        return getTitle(this.studio.title)
      },
      serviceCategoryTitle() {
        return getTitle(this.serviceCategory.title)
      },
      services() {
        return this.studio.services.filter(service => {
          return (
            service.service_category &&
            service.service_category._meta.uid == this.serviceCategoryUid
          )
        })
      },
      serviceCategories() {
        return getAllServiceCategories(this.studio)
      },
    },

    methods: {
      ...mapActions(['openContactModal']),
    },

    async asyncData({ app, params, error }) {
      try {
        const { data: data1 } = await app.apolloProvider.defaultClient.query({
          query: fetchStudioByUID,
          variables: {
            uid: params.studioUid,
          },
        })

        const { data: data2 } = await app.apolloProvider.defaultClient.query({
          query: fetchServiceCategoryByUID,
          variables: {
            uid: params.serviceCategoryUid,
          },
        })

        const studioResponse = data1.allStudios.edges

        const serviceCategoryResponse = data2.allService_categorys.edges

        if (studioResponse.length && serviceCategoryResponse.length) {
          const studio = studioResponse[0].node
          const serviceCategory = serviceCategoryResponse[0].node

          app.store.dispatch('setMetaInfo', {
            meta: studio,
            title:
              studio.title[0].text +
              ' ' +
              serviceCategory.title[0].text +
              ' Services',
            description: studio.services_description,
            image: studio.featured_image,
            $liveChat: app.$liveChat,
          })

          return {
            studio: studio,
            serviceCategory: serviceCategory,
          }
        } else {
          error({ statusCode: 404, message: 'This page could not be found' })
        }
      } catch (err) {
        console.log(err)
        error({ statusCode: 500, message: 'An error has occured' })
      }
    },
  }
</script>
