import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app, $config, store }) => {
  Vue.use(
    VueGtag,
    {
      appName: 'Gee Beauty',
      config: {
        id: $config[store.state.locale].tracking.ga,
        params: { send_page_view: false },
      },
      includes: [
        {
          id: $config[store.state.locale].tracking.adWords,
          params: { send_page_view: false },
        },
      ],
      pageTrackerScreenviewEnabled: true,
    },
    app.router
  )
}
