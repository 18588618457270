<template>
  <div class="form-page">
    <PageTitle title="Password Reset" />
    <section>
      <div class="row">
        <div class="column small-12 medium-10 large-8 xlarge-6 xxlarge-4">
          <form
            v-if="!invalidCreds"
            class="form"
            :class="{ 'has-errors': $v.$anyError }"
            @submit.prevent="handleSubmit"
          >
            <div class="form__row">
              <div
                class="form__field"
                :class="{ 'has-error': $v.password.$error }"
              >
                <label class="accessible" for="password">
                  Password (Required)
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  v-model.trim="password"
                />
                <span
                  class="error"
                  v-if="$v.password.$error && !$v.password.required"
                >
                  This field is required
                </span>
                <span
                  class="error"
                  v-if="$v.password.$error && !$v.password.minLength"
                >
                  Your password must be a minimum of
                  {{ $v.password.$params.minLength.min }} characters long
                </span>
              </div>
            </div>
            <div class="form__row">
              <div
                class="form__field"
                :class="{ 'has-error': $v.confirm_password.$error }"
              >
                <label class="accessible" for="confirm_password">
                  Confirm Password (Required)
                </label>
                <input
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  placeholder="Confirm Password"
                  v-model.trim="confirm_password"
                />
                <span
                  class="error"
                  v-if="
                    $v.confirm_password.$error && !$v.confirm_password.required
                  "
                >
                  This field is required
                </span>
                <span
                  class="error"
                  v-if="
                    $v.confirm_password.$error && !$v.confirm_password.minLength
                  "
                >
                  Your password must be a minimum of
                  {{ $v.confirm_password.$params.minLength.min }} characters
                  long
                </span>
                <span
                  class="error"
                  v-if="
                    $v.confirm_password.$error && !$v.confirm_password.sameAs
                  "
                >
                  Your passwords do not match
                </span>
              </div>
            </div>
            <div class="form__row form__row--button">
              <div class="form__field">
                <Button
                  type="submit"
                  :text="buttonText"
                  :disabled="isSubmitting"
                />
                <FormFeedback
                  :showFeedback="showFeedback"
                  :submitState="submitState"
                  :submitMessage="submitMessage"
                />
              </div>
            </div>
          </form>
          <template v-else>
            <p>Credentials invalid</p>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import { required, minLength, sameAs } from 'vuelidate/lib/validators'

  import formMixin from '@/mixins/formMixin'
  import metaMixin from '@/mixins/metaMixin'

  import { resetCustomerPassword } from '@/shopify/customer'

  import PageTitle from '@/components/global/PageTitle'
  import Button from '@/components/global/Button'
  import FormFeedback from '@/components/global/FormFeedback'

  export default {
    name: 'PasswordReset',

    hideFollow: true,

    mixins: [formMixin, metaMixin],

    components: {
      PageTitle,
      Button,
      FormFeedback,
    },

    data() {
      return {
        password: null,
        confirm_password: null,
        defaultButtonText: 'Reset Password',
      }
    },

    computed: {
      ...mapState({
        locale: state => state.locale,
      }),
      shopifyId() {
        return Buffer.from(
          `gid://shopify/Customer/${this.$route.params.shopify_id}`
        ).toString('base64')
      },
      resetToken() {
        return this.$route.query.token
      },
      invalidCreds() {
        return !this.shopifyId || !this.resetToken
      },
    },

    validations: {
      password: {
        required,
        minLength: minLength(7),
      },
      confirm_password: {
        required,
        minLength: minLength(7),
        sameAs: sameAs('password'),
      },
    },

    methods: {
      async handleSubmit() {
        this.$v.$touch()
        this.showFeedback = false
        this.isSubmitting = true

        if (this.$v.$invalid) {
          this.handleFormFeedback('error')
        } else {
          const { data } = await this.$shopify.client.mutate({
            mutation: resetCustomerPassword,
            variables: {
              id: this.shopifyId,
              input: {
                resetToken: this.resetToken,
                password: this.password,
              },
            },
          })

          const { customerUserErrors } = data.customerReset

          if (customerUserErrors.length) {
            console.log(customerUserErrors)
            this.handleFormFeedback('error', 'Reset token is not valid')
            throw new Error('Error with Shopify')
          }

          this.handleFormFeedback(
            'success',
            'You have successfully reset your password!'
          )

          if (process.client) {
            localStorage.removeItem(`gee_access_token_${this.locale}`)
          }

          this.$store.dispatch(
            'setAccessToken',
            data.customerReset.customerAccessToken
          )

          setTimeout(() => {
            this.$router.push({ name: 'home' })
          }, 2500)
        }
      },
    },

    asyncData({ app, params, error }) {
      app.store.dispatch('setMetaInfo', {
        title: 'Password Reset',
        $liveChat: app.$liveChat,
      })
    },
  }
</script>
