import gql from 'graphql-tag'

import {
  ArticleCardFragment,
  ProductCardFragment,
  SimpleProductCardFragment,
} from '../fragments'

export const fetchShopLandingDocument = gql`
  query {
    allShop_landings {
      edges {
        node {
          description
          featured_image
          callout
          callout_article {
            ...ArticleCardFragment
          }
          callout_product {
            ...ProductCardFragment
          }
          meta_title
          meta_keywords
          meta_image
          _meta {
            uid
            id
            type
          }
        }
      }
    }
  }
  ${ArticleCardFragment}
  ${ProductCardFragment}
`

export const fetchBrandDocument = gql`
  query ($uid: String!) {
    allBrands(uid: $uid) {
      edges {
        node {
          title
          description
          featured_image
          hide_from_locale
          has_no_products
          _meta {
            uid
            id
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
`

export const fetchProductTypeDocument = gql`
  query ($uid: String!) {
    allProduct_types(uid: $uid) {
      edges {
        node {
          title
          description
          featured_image
          _meta {
            uid
            id
          }
          callout
          callout_article {
            ...ArticleCardFragment
          }
          callout_product {
            ...ProductCardFragment
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
  ${ArticleCardFragment}
  ${ProductCardFragment}
`

export const fetchShopCategoryDocument = gql`
  query ($uid: String!) {
    allShop_categorys(uid: $uid) {
      edges {
        node {
          title
          description
          featured_image
          _meta {
            uid
            id
          }
          callout
          callout_article {
            ...ArticleCardFragment
          }
          callout_product {
            ...ProductCardFragment
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
  ${ArticleCardFragment}
  ${ProductCardFragment}
`

export const fetchCollectionDocument = gql`
  query ($uid: String!) {
    allCollections(uid: $uid) {
      edges {
        node {
          title
          description
          featured_image
          _meta {
            uid
            id
          }
          order_relevance_manually
          callout
          callout_article {
            ...ArticleCardFragment
          }
          callout_product {
            ...ProductCardFragment
          }
          show_product_variants
          show_only_on_ca
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
  ${ArticleCardFragment}
  ${ProductCardFragment}
`

export const fetchBrandDocumentId = gql`
  query ($uid: String!) {
    allBrands(uid: $uid) {
      edges {
        node {
          _meta {
            uid
            id
          }
        }
      }
    }
  }
`

export const fetchAllBrands = gql`
  query {
    allBrands(sortBy: title_ASC, first: 500) {
      edges {
        node {
          title
          _meta {
            uid
            id
            type
          }
          featured_image
          description
          hide_from_locale
        }
      }
    }
  }
`

export const fetchAllProducts = gql`
  query ($cursor: String!, $numResults: Int!) {
    allProducts(after: $cursor, first: $numResults) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...ProductCardFragment
        }
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchBrandProducts = gql`
  query ($cursor: String!, $uid: String!, $numResults: Int!) {
    allProducts(after: $cursor, first: $numResults, where: { brand: $uid }) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...ProductCardFragment
          product_type {
            ... on Product_type {
              title
              _meta {
                uid
                id
                type
              }
              shopCategory {
                ... on Shop_category {
                  _meta {
                    id
                    uid
                    type
                  }
                }
              }
            }
          }
        }
        cursor
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchProductTypeProducts = gql`
  query ($cursor: String!, $uid: String!, $numResults: Int!) {
    allProducts(
      after: $cursor
      first: $numResults
      where: { product_type: $uid }
    ) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...ProductCardFragment
          product_type {
            ... on Product_type {
              title
              _meta {
                uid
                id
                type
              }
              shopCategory {
                ... on Shop_category {
                  _meta {
                    id
                    uid
                    type
                  }
                }
              }
            }
          }
        }
        cursor
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchCollectionProducts = gql`
  query ($uid: String!) {
    allCollections(uid: $uid) {
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        cursor
        node {
          title
          featured_image
          _meta {
            uid
            id
            type
          }
          products {
            product {
              ... on Product {
                ...ProductCardFragment
                product_type {
                  ... on Product_type {
                    title
                    _meta {
                      uid
                      id
                      type
                    }
                    shopCategory {
                      ... on Shop_category {
                        _meta {
                          id
                          uid
                          type
                        }
                      }
                    }
                  }
                }
              }
            }
            canadian_variant_associated_sku
            us_variant_associated_sku
          }
        }
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchAllConcerns = gql`
  query {
    allConcerns(sortBy: title_ASC, first: 500) {
      edges {
        node {
          title
          _meta {
            uid
            id
            type
          }
          featured_image
          description
        }
      }
    }
  }
`

export const searchProducts = gql`
  query ($uid: String!, $cursor: String!) {
    allProducts(fulltext: $uid, after: $cursor) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          _meta {
            uid
            id
            type
          }
          ... on Product {
            ...SimpleProductCardFragment
          }
        }
      }
    }
  }
  ${SimpleProductCardFragment}
`

export const fetchHighlightByUIDForFilter = gql`
  query ($uid: String!) {
    allCollections(uid: $uid) {
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        cursor
        node {
          title
          products {
            product {
              ... on Product {
                ...ProductCardFragment
                product_type {
                  ... on Product_type {
                    title
                    _meta {
                      uid
                      id
                      type
                    }
                    shopCategory {
                      ... on Shop_category {
                        _meta {
                          id
                          uid
                          type
                        }
                      }
                    }
                  }
                }
              }
            }
            canadian_variant_associated_sku
            us_variant_associated_sku
          }
        }
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchConcernsForFilters = gql`
  query {
    allConcerns {
      edges {
        node {
          title
          _meta {
            uid
            id
          }
        }
      }
    }
  }
`

export const fetchConcernByUIDForFilter = gql`
  query ($uid: String!) {
    allConcerns(uid: $uid) {
      edges {
        node {
          title
          _meta {
            uid
          }
          callout_collection {
            ... on Collection {
              title
              _meta {
                uid
              }
              products {
                product {
                  ...ProductCardFragment
                }
              }
            }
          }
          featured_products {
            product {
              ...ProductCardFragment
            }
          }
        }
      }
    }
  }
  ${ProductCardFragment}
`
