import Vue from 'vue';

import {
    prettify,
    slugify,
    capitalize,
    date,
    dateTime,
    currency,
    truncate,
    getTitle,
    fromNow
} from '@/helpers';

Vue.filter('prettify', prettify);
Vue.filter('slugify', slugify);
Vue.filter('capitalize', capitalize);
Vue.filter('date', date);
Vue.filter('dateTime', dateTime);
Vue.filter('currency', currency);
Vue.filter('truncate', truncate);
Vue.filter('getTitle', getTitle);
Vue.filter('fromNow', fromNow);
