import gql from "graphql-tag";

export const UrlFragment = gql`
  fragment UrlFragment on _Linkable {
    __typename
    ... on _ExternalLink {
      url
    }
    ... on Homepage {
      _meta {
        type
      }
    }
    ... on Page {
      title
      _meta {
        uid
        type
      }
    }
    ... on About {
      title
      _meta {
        type
      }
    }
    ... on Blog_landing {
      _meta {
        type
      }
    }
    ... on Studio {
      title
      _meta {
        uid
        type
      }
    }
    ... on Shop_landing {
      _meta {
        type
      }
    }
    ... on Shop_category {
      title
      _meta {
        uid
        type
      }
    }
    ... on Product_type {
      title
      shopCategory {
        ... on Shop_category {
          _meta {
            uid
            type
          }
        }
      }
      _meta {
        uid
        type
      }
    }
    ... on Collection {
      title
      _meta {
        uid
        type
      }
    }
    ... on Brand {
      title
      _meta {
        uid
        type
      }
    }
    ... on Brand_landing {
      _meta {
        type
      }
    }
    ... on Custom_brand_landing {
      title
      _meta {
        uid
        type
      }
    }
    ... on Concern {
      title
      _meta {
        uid
        type
      }
    }
    ... on Concern_landing {
      _meta {
        type
      }
    }
    ... on Custom_landing {
      title
      _meta {
        uid
        type
      }
    }
    ... on Product {
      title
      _meta {
        uid
        type
      }
    }
    ... on Article {
      title
      _meta {
        uid
        type
      }
    }
    ... on Blog_category {
      title
      _meta {
        uid
        type
      }
    }
    ... on Author {
      name
      _meta {
        uid
        type
      }
    }
    ... on Beauty_marks {
      _meta {
        type
      }
    }
    ... on Service_category {
      title
      _meta {
        uid
        type
      }
    }
  }
`;

export const ArticleCardFragment = gql`
  fragment ArticleCardFragment on Article {
    title
    _meta {
      uid
      id
      type
    }
    featured_image
    is_video_article
    category {
      ... on Blog_category {
        title
        _meta {
          uid
          id
          type
        }
      }
    }
    author {
      ... on Author {
        name
        _meta {
          uid
          id
          type
        }
      }
    }
  }
`;

export const ProductCardFragment = gql`
  fragment ProductCardFragment on Product {
    title
    _meta {
      uid
      id
      type
      firstPublicationDate
    }
    shopify_product_ca
    shopify_product_us
    is_draft
    is_best_seller
    is_kit
    is_unavailable_ca
    is_unavailable_us
    unavailable_variant_skus_ca
    unavailable_variant_skus_us
    coming_soon_variant_skus_ca
    coming_soon_variant_skus_us
    tag_override
    is_bundle
    bundle_products {
      product {
        ... on Product {
          shopify_product_ca
          shopify_product_us
        }
      }
    }
    add_to_bag_override_link_ca {
      ...UrlFragment
    }
    add_to_bag_override_link_us {
      ...UrlFragment
    }
    add_to_bag_override_text_ca
    add_to_bag_override_text_us
    description
    importance
    shop_category_importance
    product_type_importance
    brand_importance
    callout_subtitle
    featured_text
    product_type {
      ... on Product_type {
        title
        _meta {
          uid
          type
        }
      }
    }
    brand {
      ... on Brand {
        title
        login_to_view_pricing_on_us
        _meta {
          uid
          id
          type
          firstPublicationDate
        }
      }
    }
    size
    is_pre_order
    is_coming_soon
  }
  ${UrlFragment}
`;

export const SimpleProductCardFragment = gql`
  fragment SimpleProductCardFragment on Product {
    title
    _meta {
      uid
      id
      type
    }
    shopify_product_ca
    shopify_product_us
    is_draft
    is_best_seller
    is_unavailable_ca
    is_unavailable_us
    unavailable_variant_skus_ca
    unavailable_variant_skus_us
    coming_soon_variant_skus_ca
    coming_soon_variant_skus_us
    tag_override
    is_bundle
    bundle_products {
      product {
        ... on Product {
          shopify_product_ca
          shopify_product_us
        }
      }
    }
    featured_text
    importance
    shop_category_importance
    product_type_importance
    brand_importance
    brand {
      ... on Brand {
        title
        _meta {
          uid
          id
          type
        }
      }
    }
    is_pre_order
    is_coming_soon
  }
`;
