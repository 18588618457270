<template xmlns="http://www.w3.org/1999/html">
  <div class="product-single" :class="{ 'gift-card': isGiftCard }">
    <Chat :showText="showChatBubbleText" />
    <section class="product-single__main">
      <div class="row">
        <div class="column small-12 xlarge-7" ref="stickyParent">
          <div class="sticky" ref="stickyEl">
            <Gallery
              :shades="shades"
              :hoveredShadeName="selections.shade.hovered"
              :selectedShadeName="selections.shade.selected"
              :featuredImage="featuredImage"
              :otherImages="otherImages"
              :isCollage="product.is_collage_product"
              :video="product.video"
              :previewVideo="product.preview_video"
              :isKit="isKit"
              :isBundle="isBundle"
            />
          </div>
        </div>
        <div class="column small-12 medium-10 xlarge-5 xxxlarge-4">
          <Seal v-if="isSkincare" />

          <div
            id="info"
            ref="mainInfo"
            class="product-single__main__info product">
            <ProductTag v-if="!isBundle" :text="tagText" />
            <ProductTag
              v-else-if="bundleDiscount"
              :text="`${bundleDiscount}% Off Bundle Discount`" />

            <div class="brand" :class="{ 'has-icons': hasIcons }">
              <BrandLink
                v-if="product.brand || isBundle"
                :brand="product.brand"
                :isBundle="isBundle" />
              <Icons
                v-if="hasIcons"
                :isDay="productData.is_am"
                :isNight="productData.is_pm" />
            </div>

            <div class="title" :class="{ 'has-icons': hasIcons }">
              <h1>
                <div>{{ title }}</div>
                <Icons
                  v-if="hasIcons"
                  :isDay="productData.is_am"
                  :isNight="productData.is_pm" />
              </h1>
            </div>

            <template v-if="isShoppingEnabled">
              <template v-if="!isBundle">
                <form v-if="!isGiftCard" class="form" @submit.prevent>
                  <ProductShadePicker
                    type="mobile"
                    :shades="shades"
                    v-model="selections.shade"
                    :isKit="isKit" />

                  <prismic-rich-text
                    v-if="product.description"
                    :field="product.description"
                    class="description wysiwyg" />

                  <div class="form__row">
                    <div class="form__field reviews-size">
                      <ProductSizePicker
                        :sizes="sizes"
                        :label="sizeLabel"
                        :singleSize="product.size"
                        v-model="selections.size" />
                      <ReviewsOverview
                        :reviews="reviews"
                        @click="openReviews" />
                    </div>
                  </div>

                  <ProductShadePicker
                    :shades="shades"
                    v-model="selections.shade"
                    :isKit="isKit" />

                  <div v-if="prices.subscription" class="form__row">
                    <div class="form__field">
                      <Frequency
                        :frequencies="getFrequencies"
                        :prices="prices"
                        :salePrices="salePrices"
                        v-model="selections.frequency" />
                    </div>
                  </div>

                  <div class="form__row">
                    <div class="form__field">
                      <button
                        v-if="product.brand && product.brand.login_to_view_pricing_on_us && locale == 'en-us' && !isLoggedIn"
                        class="button button--add-to-bag pp-no-hide"
                        @click="openHeaderMenuExpand('login')">
                        Log In to view pricing
                      </button>
                      <AddToBag
                        v-else
                        v-model="selections.quantity.selected"
                        :product="selectedProduct"
                        :overrideLink="addToBagOverrideLink"
                        :overrideText="addToBagOverrideText"
                        :defaultPrice="defaultPrice"
                        :defaultSalePrice="defaultSalePrice"
                        :isSoldOut="
                          isSoldOut || isSelectedVariantSoldOut || !selectedProduct
                        "
                        :isUnavailable="isUnavailable"
                        :isComingSoon="isComingSoon || isSelectedVariantComingSoon"
                        :isPreOrder="isPreOrder"
                        :preOrderText="preOrderText" />
                    </div>
                  </div>
                </form>

                <template v-else>
                  <form class="form" @submit.prevent>
                    <prismic-rich-text
                      v-if="product.description"
                      :field="product.description"
                      class="description wysiwyg" />

                    <div ref="step1" class="gift-card__step">
                      <div class="form__row">
                        <div class="form__field">
                          <Type v-model="giftCardType" />
                        </div>
                      </div>

                      <template v-if="giftCardType == 'online'">
                        <ValuePicker
                          :values="sizes"
                          v-model="selections.size"
                          :isGiftCard="true" />

                        <div class="form__row">
                          <div class="form__field">
                            <AddToBag
                              v-model="selections.quantity.selected"
                              :product="selectedProduct"
                              :overrideLink="addToBagOverrideLink"
                              :overrideText="addToBagOverrideText"
                              :defaultPrice="defaultPrice"
                              :defaultSalePrice="defaultSalePrice"
                              :isSoldOut="
                                isSoldOut ||
                                isSelectedVariantSoldOut ||
                                !selectedProduct
                              "
                              :isUnavailable="isUnavailable"
                              :isComingSoon="isComingSoon || isSelectedVariantComingSoon"
                              :isPreOrder="isPreOrder"
                              :preOrderText="preOrderText"
                              :hideSezzle="true" />
                          </div>
                        </div>
                      </template>
                    </div>
                  </form>
                  <div
                    v-if="
                      giftCardType == 'online' && productData.e_gift_card_text
                    "
                    class="gift-card__digital-note">
                    <SvgIcon name="gift" />
                    <p>{{ productData.e_gift_card_text }}</p>
                  </div>
                </template>
              </template>

              <BundleProducts
                v-else
                :product="product"
                :title="title"
                :shopifyProduct="shopifyProduct"
                :price="defaultPrice"
                :salePrice="defaultSalePrice"
                :bundleProducts="product.bundle_products"
                :bundleDiscount="bundleDiscount"
                :locale="locale" />
            </template>
          </div>

          <Tabs v-if="hasTabs" :tabs="tabs" />

          <slice-zone
            v-if="product.body && product.body.length"
            type="product"
            :uid="uid" />
        </div>
      </div>
    </section>

    <transition name="slide-down">
      <section
        v-if="
          isScrollShopVisible && isShoppingEnabled && !isGiftCard && !isBundle
        "
        class="product-single__scroll-shop product">
        <form class="form" @submit.prevent>
          <div class="product-single__scroll-shop__left">
            <div>
              <BrandLink v-if="product.brand" :brand="product.brand" />

              <div class="title">
                <h2 class="h3">{{ title }}</h2>
                <ProductSizePicker
                  type="scroll"
                  :sizes="sizes"
                  :label="sizeLabel"
                  :singleSize="product.size"
                  v-model="selections.size" />
              </div>
            </div>
          </div>

          <div class="product-single__scroll-shop__right">
            <ProductShadePicker
              type="scroll"
              :shades="shades"
              v-model="selections.shade"
              :isKit="isKit" />

            <a v-if="isPreOrder" href="#info" v-smooth-scroll class="button">
              Select Options
            </a>
            <AddToBag
              v-else
              v-model="selections.quantity.selected"
              :product="selectedProduct"
              :overrideLink="addToBagOverrideLink"
              :overrideText="addToBagOverrideText"
              :defaultPrice="defaultPrice"
              :defaultSalePrice="defaultSalePrice"
              :isSoldOut="isSoldOut || isSelectedVariantSoldOut || !selectedProduct"
              :isUnavailable="isUnavailable"
              :isComingSoon="isComingSoon || isSelectedVariantComingSoon"
              :hideQuantity="true"
              :hideBackInStock="true"
              :hideSezzle="true" />
          </div>
        </form>
      </section>
    </transition>

    <slice-zone
      v-if="product.body1 && product.body1.length"
      type="product"
      :uid="uid"
      body="body1"
      class="product-single__full-widgets" />

    <Quotes :quotes="productData.quotes" />

    <Reviews
      v-if="showReviews && !isGiftCard && !isBundle"
      :reviews="reviews"
      :product="product"
      :productId="productId"
      :isOpen="reviewsOpen"
      @toggleReviews="toggleReviews"
      @loadMore="fetchReviews" />

    <Collection
      v-if="hasRelatedProducts"
      :title="
        productData.related_products_title
          ? productData.related_products_title
          : productData.global_related_products_title
      "
      :subtitle="
        productData.related_products_subtitle
          ? productData.related_products_subtitle
          : productData.global_related_products_subtitle
      "
      :description="
        productData.related_products_description
          ? productData.related_products_description
          : productData.global_related_products_description
      "
      :products="relatedProducts" />

    <AboutTheGees v-if="productData.show_about_the_gees" />
  </div>
</template>

<script>
  import axios from 'axios'
  import {mapActions, mapGetters, mapState} from 'vuex'
  import { getLocaleOnServer } from '@/services/LocaleService'

  import SliceZone from 'vue-slicezone'

  import {
    fetchProductByUID_SSR,
    fetchRelatedProducts,
  } from '@/prismic/queries'

  import { removeOtherLocaleProducts, sanitizeShopifyId } from '@/helpers'

  import stickyMixin from '@/mixins/stickyMixin'
  import productMixin from '@/mixins/productMixin'
  import metaMixin from '@/mixins/metaMixin'

  import Chat from '@/components/global/Chat'
  import Seal from '@/components/global/Seal'
  import Icons from '@/components/shop/Icons'
  import BundleProducts from '@/components/pages/product-single/bundle/BundleProducts'
  import Tabs from '@/components/pages/product-single/Tabs'
  import Quotes from '@/components/pages/product-single/Quotes'
  import Gallery from '@/components/pages/product-single/Gallery'
  import Type from '@/components/pages/product-single/gift-card/Type'
  import ValuePicker from '@/components/pages/product-single/gift-card/ValuePicker'
  import SvgIcon from '@/components/global/SvgIcon'
  import ReviewsOverview from '@/components/shop/reviews/ReviewsOverview'
  import Reviews from '@/components/shop/reviews/Reviews'
  import AddToBag from '@/components/shop/AddToBag'
  import Frequency from '@/components/shop/Frequency'
  import ProductTag from '@/components/shop/ProductTag'
  import BrandLink from '@/components/shop/BrandLink'
  import Collection from '@/components/shop/Collection'
  import PrismicLink from '@/components/prismic/PrismicLink'
  import AboutTheGees from '@/components/slices/AboutTheGees'
  import ProductSizePicker from '@/components/shop/ProductSizePicker'
  import ProductShadePicker from '@/components/shop/ProductShadePicker'

  export default {
    name: 'ProductSingle',

    mixins: [stickyMixin, productMixin, metaMixin],

    components: {
      Chat,
      SliceZone,
      Seal,
      Icons,
      BundleProducts,
      Tabs,
      Quotes,
      Gallery,
      Type,
      ValuePicker,
      SvgIcon,
      ReviewsOverview,
      Reviews,
      AddToBag,
      Frequency,
      ProductTag,
      BrandLink,
      Collection,
      PrismicLink,
      AboutTheGees,
      ProductSizePicker,
      ProductShadePicker,
    },

    data() {
      return {
        serverlocale: '',
        headers: null,
        isSingle: true,
        infoBottom: 0,
        windowScrollTop: 0,
        collectionKeys: ['productTypes'],
        product: null,
        autoRelatedProducts: null,
        showReviews: true,
        reviews: {
          averageScore: 0,
          totalNum: 0,
          visible: null,
          hasMore: false,
          page: 1,
          token: null,
          isInitial: true,
          isReady: false,
        },
        reviewsOpen: true,
        giftCardType: 'online',
      }
    },

    computed: {
      ...mapState({
        locale: state => state.locale,
      }),

      ...mapGetters([
        'isLoggedIn'
      ]),

      uid() {
        return this.$route.params.uid
      },
      variantIdQueryParam() {
        return this.$route.query.variant
      },
      title() {
        return this.product && this.$prismic.asText(this.product.title)
      },
      showChatBubbleText() {
        return this.product && this.product.show_chat_bubble_text;
      },
      isScrollShopVisible() {
        return this.windowScrollTop > this.infoBottom
      },
      otherImages() {
        return this.product.gallery.filter(item => item.image != null)
      },
      tabs() {
        if (this.productData) {
          const tabs = []

          if (this.productData.features || this.product.details) {
            tabs.push({
              title: 'Details',
              features: this.productData.features,
              content: this.cleanText(this.product.details),
            })
          }

          if (this.productData.how_to_use) {
            tabs.push({
              title: 'How To Use',
              content: this.cleanText(this.productData.how_to_use),
            })
          }

          if (
            this.productData.key_facts_ingredients ||
            this.productData.ingredients
          ) {
            tabs.push({
              title: 'Ingredients',
              features: this.productData.key_facts_ingredients,
              content: this.cleanText(this.productData.ingredients),
            })
          }

          return tabs
        }
      },
      hasTabs() {
        const features =
          this.tabs &&
          this.tabs[0] &&
          this.tabs[0].features &&
          this.tabs[0].features[0] &&
          this.tabs[0].features[0].item

        const content =
          this.tabs &&
          this.tabs[0] &&
          this.tabs[0].content &&
          this.tabs[0].content[0] &&
          this.tabs[0].content[0].text

        return features || content
      },
      hasManualRelatedProducts() {
        return (
          this.productData &&
          this.productData.manual_related_products &&
          this.productData.manual_related_products[0]
        )
      },
      manualRelatedProducts() {
        if (this.hasManualRelatedProducts) {
          return this.productData.manual_related_products.map(
            product => product.product
          )
        }
      },
      relatedProducts() {
        let allRelatedProducts
        if (this.hasManualRelatedProducts) {
          allRelatedProducts = this.manualRelatedProducts.concat(
            this.autoRelatedProducts
          )
        } else {
          allRelatedProducts = this.autoRelatedProducts
        }

        if (allRelatedProducts) {
          const nonNullRelatedProducts = allRelatedProducts.filter(product => {
            return (
              product !== null && product._meta.id !== this.product._meta.id
            )
          })

          return removeOtherLocaleProducts(
            nonNullRelatedProducts,
            this.locale
          ).slice(0, 8)
        }
      },
      hasRelatedProducts() {
        return this.relatedProducts && this.relatedProducts.length > 0
      },
      productId() {
        return this.shopifyProduct && this.shopifyProduct.id
      },
      isGiftCard() {
        return this.product.is_gift_card
      },
      preOrderText() {
        return this.product.pre_order_text
          ? this.product.pre_order_text
          : 'This is a Pre Order product. You will be sent a payment link by email.'
      },
      getFrequencies(){
        return this.frequencies;
      }
    },

    watch: {
      async uid(newVal) {
        this.$nuxt.refresh()

        if (this.showReviews && !this.isGiftCard && !this.isBundle) {
          await this.fetchReviews()
        }

        await this.fetchProductData(newVal)
        await this.initShopping()

        this.fetchShopCategory()

        this.calculateScrollShopVisibility()
      },

      selectedVariant: {
        handler(newVal) {
          history.replaceState(
            {},
            null,
            `${window.location.pathname}?variant=${this.cleanVariantId(
              newVal.id
            )}`
          )
        },
        deep: true,
      },

      shopCategory(newVal) {
        if (newVal) this.handleKlaviyoTracking()
      },
    },

    methods: {
      ...mapActions([
        'openHeaderMenuExpand'
      ]),

      toggleReviews() {
        this.reviewsOpen = !this.reviewsOpen
      },

      openReviews() {
        this.reviewsOpen = true
      },

      cleanText(content) {
        if (content) {
          var cleanedText = content[0].text
            .replace(/‚Äú/g, '"')
            .replace(/‚Äù/g, '"')
            .replace(/√©/g, 'é')
          content[0].text = cleanedText
          return content
        }
      },

      calculateScrollShopVisibility() {
        window.addEventListener('scroll', () => {
          this.windowScrollTop =
            window.pageYOffset || document.documentElement.scrollTop
        })

        this.infoBottom =
          this.windowScrollTop +
          this.$refs.mainInfo.getBoundingClientRect().bottom
      },

      // async authenticateYotpo() {
      //   const { data } = await axios.get(`/api/yotpo?locale=${this.locale}`)

      //   this.reviews.token = data.token
      // },

      async fetchReviews() {
        // if (!this.reviews.token) {
        //   await this.authenticateYotpo()
        // }

        // if (!this.reviews.token) return false

        const { data } = await axios.get(
          `https://api.yotpo.com/v1/widget/${
            this.$config[this.locale].yotpo.reviewsKey
          }/products/${this.productId}/reviews.json?page=${
            this.reviews.page
          }&per_page=3&sort=rating&direction=desc`
        )

        const response = data.response

        if (response) {
          this.reviews.totalNum = response.bottomline.total_review
          this.reviews.averageScore = response.bottomline.average_score
          this.reviews.visible = this.reviews.isInitial
            ? response.reviews
            : [...this.reviews.visible, ...response.reviews]
          this.reviews.page = response.pagination.page + 1
          this.reviews.hasMore =
            response.pagination.page * response.pagination.per_page <
            response.pagination.total

          this.reviews.isReady = true
          this.reviews.isInitial = false
        }
      },

      async fetchRelatedData() {
        await this.fetchProductData(this.uid)

        if (
          (this.product && this.product.brand) ||
          (this.productData && this.productData.product_type)
        ) {
          const query = {
            query: fetchRelatedProducts,
            variables: {},
          }

          if (this.product && this.product.brand) {
            query.variables.brandId = this.product.brand._meta.id
          }

          if (this.productData && this.productData.product_type) {
            query.variables.productTypeId =
              this.productData.product_type._meta.id
          }

          try {
            const { data } = await this.$apolloProvider.defaultClient.query(
              query
            )
            let brandProducts
            let productTypeProducts

            if (data.brand.edges.length > 0) {
              brandProducts = data.brand.edges.map(item => item.node)
            }

            if (data.productType.edges.length > 0) {
              productTypeProducts = data.productType.edges.map(
                item => item.node
              )
            }

            const allAutoRelatedProducts =
              brandProducts.concat(productTypeProducts)

            this.autoRelatedProducts = allAutoRelatedProducts.filter(
              (item, index, self) => {
                return (
                  index === self.findIndex(t => t._meta.id === item._meta.id)
                )
              }
            )
          } catch (error) {
            console.log(error)
            this.autoRelatedProducts = []
          }
        }
      },

      handlePinterestPageVisit() {
        this.$pinterest.pageVisit(this.variantIdQueryParam)
      },
    },

    async created() {
      this.fetchShopCategory()

      await this.initShopping()
    },

    async mounted() {
      this.fetchRelatedData()

      this.calculateScrollShopVisibility()

      if (this.showReviews && !this.isGiftCard) {
        await this.fetchReviews()
      }

      if (this.shopifyProduct) {
        this.handleGoogleAnalyticsTracking()

        this.handlePinterestPageVisit()
      }
    },

    async asyncData({ app, params, error, req }) {
      try {
        const { data } = await app.apolloProvider.defaultClient.query({
          query: fetchProductByUID_SSR,
          variables: { uid: params.uid },
        })

        const { product } = data

        if (!product) {
          return error({
            statusCode: 404,
            message: "This product couldn't be found",
          })
        }

        const locale = process.server
          ? getLocaleOnServer(req.headers)
          : app.store.state.locale

        const shopifyProduct =
          locale === 'en-ca'
            ? product.shopify_product_ca
            : product.shopify_product_us

        if (!shopifyProduct) {
          return error({
            status: '404',
            message: "This product isn't available in your location",
          })
        }

        app.store.dispatch('setMetaInfo', {
          meta: product,
          title: product.title,
          brand: product.brand && product.brand.title,
          description: product.description
            ? product.description
            : product.details,
          image: shopifyProduct.image && shopifyProduct.image.src,
          type: 'product',
          price: shopifyProduct.variants[0].price,
          $liveChat: app.$liveChat,
        })

        return { product }
      } catch (err) {
        console.log(err)
        return error(err)
      }
    },

    watchQuery: true,

    jsonld() {
      const schema = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: this.title,
        url: this.url,
        image: this.metaImage,
        description: this.metaDescription,
        brand: {
          '@type': 'Brand',
          name: this.productBrand
            ? this.$prismic.asText(this.productBrand)
            : 'Gee Beauty',
        },
        offers: {
          '@type': 'AggregateOffer',
          priceCurrency: this.locale == 'en-ca' ? 'CAD' : 'USD',
          price: this.productPrice,
          lowPrice: this.lowestPrice ? this.lowestPrice : this.productPrice,
          itemCondition: 'http://schema.org/NewCondition',
          availability: `http://schema.org/${
            this.isSoldOut ? 'OutOfStock' : 'InStock'
          }`,
          offerCount: this.shopifyProduct?.variants.length ?? 1,
        },
      }

      if (this.reviews && this.reviews.length) {
        schema['aggregateRating'] = {
          '@type': 'AggregateRating',
          ratingValue: this.reviews.averageScore,
          reviewCount: this.reviews.totalNum,
        }
      }

      return schema
    },
  }
</script>
