import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";

import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";



const httpLink = createHttpLink({
  uri: ({ operationName }) => process.env.NUXT_ENV_SHOPIFY_API_ENDPOINT_CA,
  headers: {
    Accept: "application/json"
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "X-Shopify-Storefront-Access-Token": process.env.NUXT_ENV_SHOPIFY_STOREFRONT_TOKEN_CA
    }
  });

  return forward(operation);
})

const link = authMiddleware.concat(httpLink)

const cache = new InMemoryCache()


// https://v4.apollo.vuejs.org/guide-advanced/ssr.html#create-apollo-client
export const createShopifyClient = (ssr = false) => {
  const ssrObj = (ssr ? {
    ssrMode: true,
  } : {
    ssrForceFetchDelay: 100,
  })

  return new ApolloClient({
    link,
    cache, 
    ...ssrObj,
    connectToDevTools: true,
    defaultHttpLink: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })
}

export default (ssr = false) => {
  if (!ssr) {
    if (typeof window !== 'undefined') {
      const state = window.__APOLLO_STATE__
      if (state) {
        // If you have multiple clients, use `state.<client_id>`
        cache.restore(state.shopify)
      }
    }
  }

  return createShopifyClient(ssr)
};