<template>
  <div class="article" :class="{ 'lo' : isGated }">
    <ArticleTop v-if="page" :page="page" :publishedDate="publishedDate" />

    <section class="article__content">
      <div class="row">
        <Sidebar
          :showProducts="hasProducts && !isGated"
          :title="title"
          :product="products && products[0]"
        />

        <Main :authors="[page.author]" :signoff="page.signoff" :isGated="isGated">
          <prismic-rich-text :field="page.content" />

          <slice-zone
            v-if="page.body && page.body.length"
            type="article"
            :uid="uid"
          />
        </Main>
      </div>
    </section>

    <Marquee v-if="hasProducts && !isGated" :products="products" />

    <Related
      v-if="relatedArticles.length"
      :category="this.page.category"
      :articles="relatedArticles"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Cookie from 'js-cookie'

  import SliceZone from 'vue-slicezone'

  import { fetchArticleByUID, fetchRelatedArticles } from '@/prismic/queries'

  import { getTitle } from '@/helpers'

  import metaMixin from '@/mixins/metaMixin'

  import ArticleTop from '@/components/pages/article/ArticleTop'
  import Sidebar from '@/components/pages/article/Sidebar'
  import Main from '@/components/pages/article/Main'
  import Marquee from '@/components/pages/article/Marquee'
  import Related from '@/components/pages/article/Related'

  export default {
    name: 'Article',

    mixins: [metaMixin],

    components: {
      SliceZone,
      ArticleTop,
      Sidebar,
      Main,
      Marquee,
      Related
    },

    data() {
      return {
        page: null,
        relatedArticles: [],
      }
    },

    props: {
      uid: {
        type: String,
        default: null,
      },
    },

    computed: {
      ...mapGetters([
        'isLoggedIn'
      ]),
      title() {
        return getTitle(this.page.title)
      },
      publishedDate() {
        return this.page.published_date
          ? this.page.published_date
          : this.page._meta.firstPublicationDate
      },
      category() {
        if (!this.page) return null

        return this.page.category
      },
      products() {
        if (!this.page || !this.page.body) return null

        const products = this.getProductsFromBody(
          this.page.body.filter(item => item.fields)
        )

        return products ?? null
      },
      hasProducts() {
        if (this.products) {
          return this.products.length > 0
        }
      },
      disableGate() {
        if (!this.page) return null

        return this.page.disable_gate
      },
      isGated() {
        if(this.isLoggedIn || this.disableGate) {
          return false;
        } else {
          return (this.$route && this.$route.query.gate !== 'false') ? Cookie.get('gee_article_gate') !== 'false' : false;
        }
      }
    },

    watch: {
      uid() {
        this.init()
      },
    },

    methods: {
      ...mapActions(['setQuickShopModalTitle', 'setQuickShopModalProducts']),

      init() {
        this.setQuickShopModalTitle(this.title)

        if (this.products) {
          this.setQuickShopModalProducts(this.products)
        }

        if (this.$fb) {
          this.$fb.contentView()
        }
      },

      getProductsFromBody(body) {
        return body
          .filter(item => item.fields) // only get items with fields
          .map(widget => {
            return widget.fields
              .map(this.mergeProductAndSku) // map over each field and  and combine into one object
              .filter(v => v !== null) // filter out any null values
          })
          .flat() // make into one flat array
      },

      mergeProductAndSku(field) {
        if (!field.product) return null

        const locale = this.$store.state.locale === 'en-ca' ? 'canadian' : 'us'
        const key = `${locale}_variant_associated_sku`

        const variantId = this.findProductVariantId(field.product, field[key])

        return { ...field.product, variantId: variantId }
      },

      findProductVariantId(product, sku) {
        const shopifyProd =
          this.$store.state.locale === 'en-ca'
            ? product.shopify_product_ca
            : product.shopify_product_us

        if (!shopifyProd) return null

        const variantBySku = shopifyProd.variants.find(
          variant => variant.sku === sku
        )

        if (!variantBySku) return null

        return variantBySku.id
      },

      async fetchRelatedArticles() {
        const { category } = this.page

        if (!category) return null

        const { data } = await this.$apolloProvider.defaultClient.query({
          query: fetchRelatedArticles,
          variables: {
            categoryId: category._meta.id,
          },
        })

        this.relatedArticles = data.allArticles.edges
          .map(item => item.node)
          .filter(item => item._meta.uid !== this.page._meta.uid)
          .slice(0, 2)
      },
    },

    created() {
      this.fetchRelatedArticles()
    },

    mounted() {
      this.init()
    },

    jsonld() {
      const object = {
        '@context': 'https://schema.org',
        '@type': 'Article',
        headline: this.title,
        url: this.url,
        image: this.metaImage,
        description: this.metaDescription,
        datePublished: this.publishedDate,
      }

      if (this.page.author) {
        object.author = {
          '@type': 'Person',
          name: this.$prismic.asText(this.page.author.name),
        }
      }

      return object
    },

    async asyncData({ app, params, error }) {
      try {
        const { data: data1 } = await app.apolloProvider.defaultClient.query({
          query: fetchArticleByUID,
          variables: {
            uid: params.uid,
          },
        })

        const { edges } = data1.allArticles

        if (!edges.length) {
          error({ statusCode: 404, message: 'This page could not be found' })
        }

        const page = edges[0].node

        app.store.dispatch('setMetaInfo', {
          meta: page,
          title: page.title,
          description: page.content,
          image: page.featured_image,
          type: 'article',
        })

        return { page }
      } catch (err) {
        console.log(err)
        error({ statusCode: 500, message: 'An error has occured' })
      }
    },
  }
</script>
