<template>
  <div v-if="page" class="concern-single">
    <section class="page-title">
      <div class="row">
        <div class="column">
          <h1>
            {{ page.title | getTitle }}
          </h1>
          <p v-if="page.description">{{ page.description }}</p>
        </div>
      </div>
    </section>

    <section class="concern-single__hero">
      <div class="row">
        <div class="column hero-main">
          <PrismicLazyImage
            v-if="page.featured_image"
            :image="page.featured_image"
            width="960"
            height="630"
            :disableLazy="true"
          />
          <h4 v-if="page.subtitle">{{ page.subtitle }}</h4>
          <div v-if="page.content" class="wysiwyg">
            <MoreLessText>
              <template v-slot:text>
                <prismic-rich-text :field="page.content" />
              </template>
              <template v-if="page.more_content" v-slot:moreText>
                <prismic-rich-text :field="page.more_content" />
              </template>
            </MoreLessText>
          </div>
        </div>
        <div v-if="primaryFeatures" class="column hero-side">
          <ConcernCard
            v-for="item in primaryFeatures"
            :key="item._meta && item._meta.id"
            :item="item"
          />
        </div>
      </div>
    </section>

    <Collection
      v-if="page.featured_products"
      :subtitle="`Top <strong>${getTitle(page.title)}</strong> Picks`"
      :description="page.featured_products_description"
      :products="page.featured_products"
    />

    <section
      v-if="secondaryFeatures.length"
      class="concern-single__feature"
    >
      <div class="row">
        <div v-for="item in secondaryFeatures" class="column small-12 large-6 xlarge-3">
          <ConcernCard
            :key="item._meta && item._meta.id"
            :item="item"
            isPortrait
          />
        </div>
        <Callout
          v-if="page.callout"
          :type="page.callout"
          :collection="page.callout_collection"
        />
      </div>
    </section>

    <template v-if="featuredCollections.length">
      <Collection
        v-for="item in featuredCollections"
        :key="item._meta && item._meta.id"
        :subtitle="`<span>${getTitle(item.title)}</span>`"
        :description="item.description"
        :to="`/shop/collections/${item._meta && item._meta.uid}`"
        :products="findProductsByCollectionUid(item._meta && item._meta.uid)"
      />
    </template>
  </div>
</template>

<script>
import {
  fetchCustomLandingByUID,
  fetchCollectionProductsByUIDs
} from "@/prismic/queries";

import { getTitle } from "@/helpers";

import metaMixin from "@/mixins/metaMixin";

import PrismicLazyImage from "@/components/prismic/PrismicLazyImage";
import MoreLessText from "@/components/global/MoreLessText";
import ConcernCard from "@/components/pages/concern-single/ConcernCard";
import LandingItem from "@/components/shop/LandingItem";
import Collection from "@/components/shop/Collection";
import Callout from "@/components/global/Callout";

export default {
  name: "CustomLanding",

  mixins: [metaMixin],

  components: {
    PrismicLazyImage,
    MoreLessText,
    ConcernCard,
    LandingItem,
    Collection,
    Callout
  },

  data() {
    return {
      page: null,
      products: null
    };
  },

  computed: {
    primaryFeatures() {
      if (!this.page.features) return null;

      return this.page.features
        .map(({ feature }) => feature)
        .filter(v => v !== null)
        .slice(0, 3);
    },

    secondaryFeatures() {
      if (!this.page.features) return null;

      return this.page.features
        .map(({ feature }) => feature)
        .filter(v => v !== null)
        .slice(3, 7);
    },

    featuredCollections() {
      if (!this.page.featured_collections) return null;

      return this.page.featured_collections
        .map(({ collection }) => collection)
        .filter(v => v !== null);
    }
  },

  methods: {
    getTitle,
    findProductsByCollectionUid(uid) {
      if (!this.products) return null;

      return this.products.find(product => product._meta.uid == uid).products;
    }
  },

  async asyncData({ app, params, error }) {
    try {
      const { data: data1 } = await app.apolloProvider.defaultClient.query({
        query: fetchCustomLandingByUID,
        variables: {
          uid: params.uid
        }
      });

      const pageResponse = data1.allCustom_landings.edges;

      if (pageResponse.length) {
        const page = pageResponse[0].node;

        app.store.dispatch("setMetaInfo", {
          meta: page,
          title: page.title[0].text,
          description: page.description,
          image: page.featured_image,
          $liveChat: app.$liveChat
        });

        let productsResponse = [];

        if(page.featured_collections && page.featured_collections[0] && page.featured_collections[0].collection) {
          const collectionUids = page.featured_collections.map(
            item => item.collection?._meta.uid
          );

          const { data: data2 } = await app.apolloProvider.defaultClient.query({
            query: fetchCollectionProductsByUIDs,
            variables: {
              uids: collectionUids
            }
          });

          productsResponse = data2.allCollections.edges;
        }

        return {
          page: page,
          products: productsResponse.length
            ? productsResponse.map(item => item.node)
            : null
        };
      } else {
        error({ statusCode: 404, message: "This page could not be found" });
      }
    } catch (err) {
      console.log(err);
      error({ statusCode: 500, message: "An error has occured" });
    }
  }
};
</script>
