<template>
  <div class="generic">
    <PageTitle :title="page.title | getTitle">
      <PrismicLazyImage
        v-if="page.featured_image"
        :image="page.featured_image"
        width="1500"
        :disableLazy="true"
      />
    </PageTitle>

    <section class="generic__content">
      <div class="row">
        <div class="column">
          <div class="wysiwyg">
            <prismic-rich-text :field="content" />

            <slice-zone
              v-if="page.body && page.body.length"
              type="page"
              :uid="uid"
              :body="showUSContent ? 'body_us' : 'body'" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import SliceZone from 'vue-slicezone'

  import { fetchPageByUID } from '@/prismic/queries'

  import metaMixin from '@/mixins/metaMixin'

  import PageTitle from '@/components/global/PageTitle'
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage'

  export default {
    name: 'Generic',

    mixins: [metaMixin],

    components: {
      SliceZone,
      PageTitle,
      PrismicLazyImage,
    },

    data() {
      return {
        page: null,
        showUSContent: false,
      }
    },

    props: {
      uid: {
        type: String,
        default: null,
      },
    },

    computed: {
      content() {
        if (this.$store.state.locale === 'en-ca') return this.page.content

        return this.page.content_us ?? this.page.content
      },
    },

    async asyncData({ app, params, store, error }) {
      try {
        const { data } = await app.apolloProvider.defaultClient.query({
          query: fetchPageByUID,
          variables: {
            uid: params.uid,
          },
        })

        const { edges } = data.allPages

        if (!edges.length) {
          return error({
            statusCode: 404,
            message: 'This page could not be found',
          })
        }

        const page = edges[0].node

        app.store.dispatch('setMetaInfo', {
          meta: page,
          title: page.title,
          description: page.content,
          image: page.featured_image,
          $liveChat: app.$liveChat,
        })

        return {
          page: page,
          showUSContent: store.state.locale === 'en-us' && !!page.content_us,
        }
      } catch (err) {
        console.log(err)
        error({ statusCode: 500, message: 'An error has occured' })
      }
    },
  }
</script>
