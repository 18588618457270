<template>
  <div class="quiz">
    <Header />
    <h1 class="accessible">Skincare Quiz</h1>
    <form>
      <Counter :currentSectionIndex="currentSectionIndex" :numSections="numSections" />

      <Intro :isVisible="currentSectionIndex == 0" @click="startQuiz" />

      <Section :isVisible="currentSectionIndex == 1">
        <template v-slot:header>
          <h2>First, let's get on a <span>First Name</span> basis</h2>
        </template>
        <template v-slot:content>
          <div class="question-row">
            <div class="input-wrap">
              <label for="first_name" class="accessible">First Name</label>
              <input id="first_name" type="text" placeholder="First Name" v-model.trim="userAnswers.section1.first_name" />
              <span v-if="$v.userAnswers.section1.first_name.$error && !$v.userAnswers.section1.first_name.required" class="error">This question is required</span>
            </div>
            <div class="input-wrap">
              <label for="email" class="accessible">Email</label>
              <input id="email" type="email" placeholder="Email" v-model.trim="userAnswers.section1.email" />
              <span v-if="$v.userAnswers.section1.email.$error && !$v.userAnswers.section1.email.required" class="error">This question is required</span>
              <span v-if="$v.userAnswers.section1.email.$error && !$v.userAnswers.section1.email.email" class="error">Please enter a valid email address</span>
            </div>
          </div>
        </template>
      </Section>

      <Section :isVisible="currentSectionIndex == 2">
        <template v-slot:header>
          <h2>How Would You Describe Your Skin?</h2>
          <p>Choose All That Apply (Minimum 3)</p>
        </template>
        <template v-slot:content>
          <div class="question-row">
            <fieldset>
              <legend class="accessible">What is Your Skin Type?</legend>
              <div class="checkbox-grid">
                <label v-for="skinType in skinTypes">
                  <input
                    :value="skinType"
                    type="checkbox"
                    name="skin_type"
                    v-model="userAnswers.section2.skin_type"
                  />
                  <span><SvgIcon name="checkmark-white"/></span>{{ skinType }}
                </label>
              </div>
              <span v-if="$v.userAnswers.section2.skin_type.$error && !$v.userAnswers.section2.skin_type.required" class="error">This question is required</span>
              <span v-if="$v.userAnswers.section2.skin_type.$error && !$v.userAnswers.section2.skin_type.minLength" class="error">
                You must choose at least {{ $v.userAnswers.section2.skin_type.$params.minLength.min }} options
              </span>
            </fieldset>
          </div>
          <div class="question-row">
            <div class="input-wrap">
              <label for="skin_type_elaborate" class="accessible">Care to elaborate?</label>
              <textarea id="skin_type_elaborate" placeholder="Care to elaborate?" v-model.trim="userAnswers.section2.skin_type_elaborate"></textarea>
            </div>
          </div>
        </template>
      </Section>

      <Section :isVisible="currentSectionIndex == 3">
        <template v-slot:header>
          <h2>How Would You Describe Your Skin?</h2>
        </template>
        <template v-slot:content>
          <div class="question-row">
            <div class="input-wrap">
              <label for="skin_description" class="accessible">How Would You Describe Your Skin?</label>
              <textarea id="skin_description" placeholder="Start Typing" v-model.trim="userAnswers.section3.skin_description"></textarea>
              <span v-if="$v.userAnswers.section3.skin_description.$error && !$v.userAnswers.section3.skin_description.required" class="error">This question is required</span>
            </div>
          </div>
        </template>
      </Section>

      <Section :isVisible="currentSectionIndex == 4">
        <template v-slot:header>
          <h2>How Many Steps Could You Commit To?</h2>
        </template>
        <template v-slot:content>
          <div class="question-row">
            <fieldset>
              <legend class="accessible">How Many Steps Could You Commit To?</legend>
              <div class="radio-grid">
                <label>
                  <input type="radio" name="steps" value="1-2" v-model="userAnswers.section4.steps" /><span>1-2</span>
                </label>
                <label>
                  <input type="radio" name="steps" value="3-4" v-model="userAnswers.section4.steps" /><span>3-4</span>
                </label>
                <label>
                  <input type="radio" name="steps" value="5-6" v-model="userAnswers.section4.steps" /><span>5-6+</span>
                </label>
              </div>
              <span v-if="$v.userAnswers.section4.steps.$error && !$v.userAnswers.section4.steps.required" class="error">This question is required</span>
            </fieldset>
          </div>
        </template>
      </Section>

      <Section :isVisible="currentSectionIndex == 5">
        <template v-slot:header>
          <h2>Do You Take any Medication?</h2>
        </template>
        <template v-slot:content>
          <div class="question-row">
            <fieldset>
              <legend class="accessible">Do You Take any Medication?</legend>
              <div class="radio-grid">
                <label>
                  <input type="radio" name="medications" value="Yes" v-model="userAnswers.section5.medications" /><span>Yes</span>
                </label>
                <label>
                  <input type="radio" name="medications" value="No" v-model="userAnswers.section5.medications" /><span>No</span>
                </label>
              </div>
              <span v-if="$v.userAnswers.section5.medications.$error && !$v.userAnswers.section5.medications.required" class="error">This question is required</span>
            </fieldset>
          </div>
        </template>
      </Section>

      <Section :isVisible="currentSectionIndex == 6 && showSetPassword">
        <template v-slot:header>
          <h2>We’re Working On Your Custom Regimen</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc justo ex, viverra non congue vel, varius a erat. Nulla egestas lectus ac lacus blandit aliquam.</p>
        </template>
        <template v-slot:content>
          <div class="question-row">
            <div class="input-wrap">
              <label for="password" class="accessible">Create Password</label>
              <input
                id="password"
                type="password"
                placeholder="Create Password"
                v-model.trim="userAnswers.section6.password"
              />
              <span v-if="$v.userAnswers.section6.password.$error && !$v.userAnswers.section6.password.required" class="error">This question is required</span>
              <span v-if="$v.userAnswers.section6.password.$error && !$v.userAnswers.section6.password.minLength" class="error">
                Your password must be a minimum of {{ $v.userAnswers.section6.password.$params.minLength.min }} characters long
              </span>
            </div>
            <div class="input-wrap">
              <label for="confirm_password" class="accessible">Confirm Password</label>
              <input
                id="confirm_password"
                type="password"
                placeholder="Confirm Password"
                v-model.trim="userAnswers.section6.confirm_password"
              />
              <span v-if="$v.userAnswers.section6.confirm_password.$error && !$v.userAnswers.section6.confirm_password.required" class="error">This question is required</span>
              <span v-if="$v.userAnswers.section6.confirm_password.$error && !$v.userAnswers.section6.confirm_password.minLength" class="error">
                Your password must be a minimum of {{ $v.userAnswers.section6.confirm_password.$params.minLength.min }} characters long
              </span>
              <span v-if="$v.userAnswers.section6.confirm_password.$error && !$v.userAnswers.section6.confirm_password.sameAs" class="error">Your passwords do not match</span>
            </div>
          </div>
        </template>
      </Section>

      <Outro :isVisible="currentSectionIndex > numSections" />

      <Progress
        :currentSectionIndex="currentSectionIndex"
        :numSections="numSections"
        @goToPrevSection="goToPrevSection"
        @goToNextSection="goToNextSection(`section${currentSectionIndex}`)"
      />
    </form>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';

  import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';

  import metaMixin from '@/mixins/metaMixin';

  import Header from '@/components/pages/quiz/Header';
  import Counter from '@/components/pages/quiz/Counter';
  import Intro from '@/components/pages/quiz/Intro';
  import Section from '@/components/pages/quiz/Section';
  import Outro from '@/components/pages/quiz/Outro';
  import SvgIcon from '@/components/global/SvgIcon';
  import Progress from '@/components/pages/quiz/Progress';

  export default {
    name: 'Quiz',

    layout: 'quiz',

    mixins: [ metaMixin ],

    components: {
      Header,
      Counter,
      Intro,
      Section,
      Outro,
      SvgIcon,
      Progress
    },

    data() {
      return {
        currentSectionIndex: 0,
        showSetPassword: true,
        userAnswers: {
          section1: {
            first_name: '',
            email: ''
          },
          section2: {
            skin_type: [],
            skin_type_elaborate: ''
          },
          section3: {
            skin_description: ''
          },
          section4: {
            steps: ''
          },
          section5: {
            medications: ''
          },
          section6: {
            password: null,
            confirm_password: null
          }
        },
        skinTypes: ['Pigmented', 'Acne Prone', 'Normal', 'Aging', 'Combination', 'Dry', 'Oily', 'Dehydrated', 'Sensitive']
      }
    },

    computed: {
      ...mapState({
        userData: state => state.user.data
      }),
      ...mapGetters([
        'isLoggedIn'
      ]),
      numSections() {
        return this.showSetPassword ? 6 : 5;
      }
    },

    validations: {
      userAnswers: {
        section1: {
          first_name: {
            required
          },
          email: {
            required,
            email
          }
        },
        section2: {
          skin_type: {
            required,
            minLength: minLength(3)
          }
        },
        section3: {
          skin_description: {
            required
          }
        },
        section4: {
          steps: {
            required
          }
        },
        section5: {
          medications: {
            required
          }
        },
        section6: {
          password: {
            required,
            minLength: minLength(7)
          },
          confirm_password: {
            required,
            minLength: minLength(7),
            sameAs: sameAs('password')
          }
        }
      }
    },

    watch: {
      email() {
        if(this.isLoggedIn && this.userData) {
          // If they change the auto populated email (from being logged in) to a new one
          this.showSetPassword = this.email !== this.userData.email;
        }
      }
    },

    methods: {
      goToPrevSection() {
        this.currentSectionIndex -= 1;
      },
      goToNextSection(sectionName) {
        if((sectionName && this.validateSection(sectionName)) || !sectionName) {
          this.currentSectionIndex += 1;
        }
      },
      validateSection(sectionName) {
        this.$v.userAnswers[sectionName].$touch();

        return !this.$v.userAnswers[sectionName].$invalid;
      },
      startQuiz() {
        if(this.isLoggedIn && this.userData) {
          this.first_name = this.userData.firstName;
          this.email = this.userData.email;
        }

        this.goToNextSection();
      }
    },

    asyncData({ app, params, error }) {
      app.store.dispatch('setMetaInfo', {
        title: 'Skincare Quiz',
        $liveChat: app.$liveChat
      });
    }
  }
</script>
