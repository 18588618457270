<template>
  <div class="account">
    <div v-show="isLoggedIn">
      <div class="row">
        <div class="account__main column small-12 large-9">
          <section>
            <transition>
              <NuxtChild
                :key="$route.name"
                :accessToken="accessToken"
                :addressId="addressId"
              />
            </transition>
          </section>
        </div>

        <aside
          class="account__sidebar column small-12 large-3"
          ref="stickyParent"
        >
          <div class="sticky" ref="stickyEl">
            <AccountLinks />
          </div>
        </aside>
      </div>
    </div>

    <PageTitle v-show="!isLoggedIn" :isAlone="true">
      <div class="account__none">You must be logged in to view this page.</div>
      <Button @click="openHeaderMenuExpand('login')" text="Log In" />
    </PageTitle>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex'

  import stickyMixin from '@/mixins/stickyMixin'

  import AccountLinks from '@/components/global/AccountLinks'
  import PageTitle from '@/components/global/PageTitle'
  import Button from '@/components/global/Button'

  export default {
    name: 'Account',

    hideFollow: true,

    mixins: [stickyMixin],

    components: {
      AccountLinks,
      PageTitle,
      Button,
    },

    computed: {
      ...mapState({
        accessToken: state => state.user.shopifyToken.accessToken,
      }),

      ...mapGetters(['isLoggedIn']),

      addressId() {
        return this.$route.params.address_id
      },
    },

    methods: {
      ...mapActions(['openHeaderMenuExpand']),
    },
  }
</script>
