<template>
  <div v-if="page" class="brand-landing">
    <LandingTop :model="page" title="Brands" type="brandsLanding" />
    <LandingItem
      v-for="brand in brandsToShow"
      :key="brand._meta.uid"
      :item="brand"
      type="brand" />
  </div>
</template>

<script>
  import {
    fetchBrandLandingData,
    fetchCustomBrandLandingData,
  } from '@/prismic/queries'
  import { fetchAllBrands } from '@/prismic/queries/landingPage'

  import { filterHiddenItems } from '@/services/MegaMenuService'

  import { getTitle } from '@/helpers'

  import metaMixin from '@/mixins/metaMixin'

  import LandingTop from '@/components/shop/LandingTop'
  import LandingItem from '@/components/shop/LandingItem'

  export default {
    name: 'BrandLanding',

    mixins: [metaMixin],

    components: {
      LandingTop,
      LandingItem,
    },

    data() {
      return {
        page: null,
        brands: null,
      }
    },

    computed: {
      brandsToShow() {
        const brands = this.sort(this.brands)
        const { locale } = this.$store.state

        return filterHiddenItems(locale, brands) ?? []
      },
    },

    methods: {
      sort(brands) {
        return brands.sort((a, b) => {
          const aTitle = getTitle(a.title)
          const bTitle = getTitle(b.title)

          return aTitle.localeCompare(bTitle, 'en', {
            sensitivity: 'base',
          })
        })
      },
    },

    mounted() {
      this.$pinterest.pageVisit()
    },

    async asyncData({ app, params, error }) {
      try {
        const uid = params && params.uid
        const isCustom = uid ? true : false

        const { data: data1 } = await app.apolloProvider.defaultClient.query({
          query: isCustom ? fetchCustomBrandLandingData : fetchBrandLandingData,
          variables: {
            uid: uid,
          },
        })

        const pageResponse = isCustom
          ? data1.allCustom_brand_landings.edges
          : data1.allBrand_landings.edges

        let brandsResponse
        if (!isCustom) {
          const { data: data2 } = await app.apolloProvider.defaultClient.query({
            query: fetchAllBrands,
          })

          brandsResponse = data2.allBrands.edges
        }

        if (pageResponse.length) {
          const page = pageResponse[0]

          let brands
          if (isCustom) {
            brands = page.node.brands.map(brand => brand.brand)
          } else {
            brands = brandsResponse.map(brand => brand.node)
          }

          app.store.dispatch('setMetaInfo', {
            meta: page.node,
            title: isCustom ? page.node.title : 'Brands',
            description: page.node.description,
            image: page.node.featured_image,
            $liveChat: app.$liveChat,
          })

          return {
            page: page,
            brands: brands,
          }
        } else {
          error({ statusCode: 404, message: 'This page could not be found' })
        }
      } catch (err) {
        console.log(err)
        error({ statusCode: 500, message: 'An error has occured' })
      }
    },
  }
</script>
