import ApolloClient from 'apollo-client'
import { PrismicLink } from 'apollo-link-prismic'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'

import fragmentTypes from '../../prismic/fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes,
})

export const prismicClient = new ApolloClient({
  link: PrismicLink({
    uri: process.env.NUXT_ENV_PRISMIC_GRAPHQL_ENDPOINT,
  }),
  cache: new InMemoryCache({ fragmentMatcher }),
  connectToDevTools: process.client,
  ssrMode: !process.client,
  defaultHttpLink: false,
})

export default () => prismicClient
