<template>
  <div v-if="about" class="about">
    <div class="row">
      <div class="column">
        <div class="about-intro">
          <h1 class="h5" v-if="about.title">{{ about.title | getTitle }}</h1>
          <h2 v-if="about.subtitle">
            <prismic-rich-text :field="about.subtitle" />
          </h2>
        </div>
      </div>
    </div>

    <div class="row about-main">
      <div class="column about-main__photos">
        <PrismicLazyImage
          :image="about.image_1"
          width="920"
          height="680"
          class="about-photo-1"
        />
        <PrismicLazyImage
          :image="about.image_2"
          width="435"
          height="515"
          class="about-photo-2"
        />
        <blockquote v-if="about.quote" class="h3 about-photo-quote">
          “{{ about.quote }}”
        </blockquote>
        <PrismicLazyImage
          :image="about.image_3"
          width="540"
          height="680"
          class="about-photo-3"
        />
        <PrismicLazyImage
          :image="about.image_4"
          width="500"
          height="330"
          class="about-photo-4"
        />
      </div>
      <div class="column about-main__content" ref="stickyParent">
        <PrismicLazyImage
          :image="about.image_1"
          width="920"
          height="680"
          class="about-photo-1"
        />
        <PrismicLazyImage
          :image="about.image_2"
          width="435"
          height="515"
          class="about-photo-2"
        />
        <div class="sticky" ref="stickyEl">
          <div v-if="about.main_section_content" class="wysiwyg has-lede">
            <prismic-rich-text :field="about.main_section_content" />
          </div>
          <Button
            v-if="about.main_section_link"
            :link="about.main_section_link"
            text="Learn More"
          />
        </div>
        <PrismicLazyImage
          :image="about.image_3"
          width="540"
          height="680"
          class="about-photo-3"
        />
        <PrismicLazyImage
          :image="about.image_4"
          width="500"
          height="330"
          class="about-photo-4"
        />
        <blockquote v-if="about.quote" class="h3 about-photo-quote">
          “{{ about.quote }}”
        </blockquote>
        <Button
          v-if="about.main_section_link"
          :link="about.main_section_link"
          text="Learn More"
        />
      </div>
    </div>

    <AboutSection
      v-if="gees"
      :title="about.gee_section_heading"
      extraClass="about-women"
    >
      <AboutCard
        v-for="(gee, index) in gees"
        :key="index"
        :image="gee.featured_image"
        :title="gee.name | getTitle"
        :subTitle="gee.role"
        :twitterHandle="gee.twitter_handle"
        :instagramHandle="gee.instagram_handle"
        :description="`“${gee.quote}”`"
        :link="gee.learn_more_link"
      />
    </AboutSection>

    <AboutSection
      v-if="studios"
      :title="about.studio_section_heading"
      extraClass="about-studios"
    >
      <AboutCard
        v-for="(studio, index) in studios"
        :key="index"
        :image="studio.featured_image"
        :title="studio.title | getTitle"
        :subTitle="studio.address_line_1"
        :description="studio.description"
        :to="`/studio/${studio._meta.uid}`"
      />
    </AboutSection>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { fetchAboutData, fetchAllGees } from "@/prismic/queries";

import stickyMixin from "@/mixins/stickyMixin";
import metaMixin from "@/mixins/metaMixin";

import PrismicLazyImage from "@/components/prismic/PrismicLazyImage";
import Button from "@/components/global/Button";

import AboutCard from "@/components/pages/about/AboutCard";
import AboutSection from "@/components/pages/about/AboutSection";

export default {
  name: "About",

  mixins: [stickyMixin, metaMixin],

  components: {
    PrismicLazyImage,
    Button,
    AboutCard,
    AboutSection
  },

  data() {
    return {
      about: null,
      gees: null
    };
  },

  computed: {
    ...mapState({
      studios: state => state.header.links.studios[0].links
    })
  },

  async asyncData({ app, error }) {
    try {
      const { data: data1 } = await app.apolloProvider.defaultClient.query({
        query: fetchAboutData
      });

      const aboutResponse = data1.allAbouts.edges;

      const { data: data2 } = await app.apolloProvider.defaultClient.query({
        query: fetchAllGees
      });

      const geesResponse = data2.allAuthors.edges;

      if (aboutResponse.length) {
        const about = aboutResponse[0].node;

        app.store.dispatch("setMetaInfo", {
          meta: about,
          title: about.title,
          description: about.main_section_content,
          image: about.image_1,
          $liveChat: app.$liveChat
        });

        return {
          about: about,
          gees: geesResponse.length ? geesResponse.map(item => item.node) : null
        };
      } else {
        error({ statusCode: 404, message: "This page could not be found" });
      }
    } catch (err) {
      console.log(err);
      error({ statusCode: 500, message: "An error has occured" });
    }
  }
};
</script>
