<template>
  <div v-if="page" class="concern-landing">
    <LandingTop :model="page" title="Concerns" type="concernsLanding" />
    <template v-if="concerns">
      <LandingItem
        v-for="concern in concerns"
        :key="concern._meta.id"
        :item="concern"
        type="concern" />
    </template>
  </div>
</template>

<script>
  import { fetchConcernLandingData } from '@/prismic/queries'
  import { fetchAllConcerns } from '@/prismic/queries/landingPage'

  import metaMixin from '@/mixins/metaMixin'

  import LandingTop from '@/components/shop/LandingTop'
  import LandingItem from '@/components/shop/LandingItem'

  export default {
    name: 'ConcernLanding',

    mixins: [metaMixin],

    components: {
      LandingTop,
      LandingItem,
    },

    data() {
      return {
        page: null,
        concerns: null,
      }
    },

    mounted() {
      this.$pinterest.pageVisit()
    },

    async asyncData({ app, params, error }) {
      try {
        const { data: data1 } = await app.apolloProvider.defaultClient.query({
          query: fetchConcernLandingData,
        })

        const { data: data2 } = await app.apolloProvider.defaultClient.query({
          query: fetchAllConcerns,
        })

        const pageResponse = data1.allConcern_landings.edges
        const concernsResponse = data2.allConcerns.edges

        if (pageResponse.length) {
          const page = pageResponse[0]

          app.store.dispatch('setMetaInfo', {
            meta: page.node,
            title: 'Concerns',
            description: page.node.description,
            image: page.node.featured_image,
            $liveChat: app.$liveChat,
          })

          return {
            page: page,
            concerns: concernsResponse.map(concern => concern.node),
          }
        } else {
          error({ statusCode: 404, message: 'This page could not be found' })
        }
      } catch (err) {
        console.log(err)
        error({ statusCode: 500, message: 'An error has occured' })
      }
    },
  }
</script>
