<template>
  <div class="regimen">
    <section class="page-title regimen-title">
      <div class="row">
        <div class="column">
          <h1>{{ firstName }}'s Regimen</h1>
          <p class="h4">
            Hi {{ firstName }}! Based on your quiz answers I’ve made your
            regimen with a focus on <span>anti-aging</span>,
            <span>firming</span>, <span>lifting</span> and <span>dry skin</span>
          </p>
          <div class="regimen-title__composer">
            <LazyImage
              src="http://placehold.it/36x36"
              retinaSrc="http://placehold.it/72x72"
              width="36"
              height="36"
            />
            <div class="content">
              <h4>Composed By</h4>
              <span>Stephanie Gee</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="regimen-main">
      <div class="row">
        <div class="regimen-main__inner">
          <div
            class="regimen-content column"
            :class="{ 'regimen-content--apply-mode': applyMode }"
          >
            <button class="regimen-print-button" @click="printTrigger()">
              <SvgIcon name="print" />
              <span>Print Regimen Card</span>
              <SvgIcon name="chevron-right" />
            </button>

            <div class="regimen-content__tabs">
              <div
                @click="isDay = true"
                class="tab"
                :class="{ 'is-active': isDay }"
              >
                <h2><SvgIcon name="day" />A.M.</h2>
                <div class="options">
                  <button
                    :class="{ 'is-active': !applyMode }"
                    @click="modeToggle(false)"
                  >
                    <span><SvgIcon name="checkmark-white"/></span>Product View
                  </button>
                  <button
                    :class="{ 'is-active': applyMode }"
                    @click="modeToggle(true)"
                  >
                    <span><SvgIcon name="checkmark-white"/></span>How to Apply
                  </button>
                </div>
              </div>
              <div
                @click="isDay = false"
                class="tab"
                :class="{ 'is-active': !isDay }"
              >
                <h2><SvgIcon name="night" />P.M.</h2>
                <div class="options">
                  <button
                    :class="{ 'is-active': !applyMode }"
                    @click="modeToggle(false)"
                  >
                    <span><SvgIcon name="checkmark-white"/></span>Product View
                  </button>
                  <button
                    :class="{ 'is-active': applyMode }"
                    @click="modeToggle(true)"
                  >
                    <span><SvgIcon name="checkmark-white"/></span>How to Apply
                  </button>
                </div>
              </div>
            </div>

            <div class="regimen-options-mob">
              <button
                :class="{ 'is-active': !applyMode }"
                @click="modeToggle(false)"
              >
                <span><SvgIcon name="checkmark-white"/></span>Product View
              </button>
              <button
                :class="{ 'is-active': applyMode }"
                @click="modeToggle(true)"
              >
                <span><SvgIcon name="checkmark-white"/></span>How to Apply
              </button>
            </div>

            <div v-if="isDay" class="regimen-content__inner">
              <Step
                title="Cleanse"
                description="Start off with a cleanser to get rid of excess oil, dirt, and makeup, without stripping off the good stuff your skin needs for a healthy barrier."
              >
                <Product
                  image="https://via.placeholder.com/246x264"
                  image2x="https://via.placeholder.com/492x528"
                  imageWidth="246"
                  imageHeight="264"
                  brand="Test Brand"
                  name="Product Name"
                  isDay
                  isNight
                  price="30"
                  subPrice="20"
                  description="This cleanser offers a great cleansing ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacinia urna eu lectus consequat vehicula. Mauris eu elit eu sapien ullamcorper congue."
                  instructions="Apply 1 pump massaging inwards around the eye area and up along the brow-bone, and on the top of the cheekbone"
                  inRegimen
                  hasOptions
                  altImage="https://via.placeholder.com/146x164"
                  altImage2x="https://via.placeholder.com/292x328"
                  altImageWidth="146"
                  altImageHeight="164"
                  altBrand="Alt Brand"
                  altName="Alt Name"
                  altIsDay
                  altIsNight
                  altPrice="20"
                  altSubPrice="10"
                  altDescription="This is the second product."
                  altInstructions="Apply 1 pump massaging inwards around the eye area and up along the brow-bone, and on the top of the cheekbone"
                />
              </Step>
              <Step
                title="Tone"
                description="Follow with a toner to balance the skin’s pH and prepare for better absorption of serum ingredients."
              >
                <Product
                  image="https://via.placeholder.com/246x264"
                  image2x="https://via.placeholder.com/492x528"
                  imageWidth="246"
                  imageHeight="264"
                  brand="Second Brand"
                  name="Second Product"
                  isDay
                  price="40"
                  subPrice="30"
                  description="This cleanser offers a great cleansing ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacinia urna eu lectus consequat vehicula. Mauris eu elit eu sapien ullamcorper congue."
                  instructions="Apply 1 pump massaging inwards around the eye area and up along the brow-bone, and on the top of the cheekbone"
                />
              </Step>
              <Step
                title="Treat"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel arcu laoreet, viverra neque in, malesuada nunc"
              >
                <Product
                  image="https://via.placeholder.com/246x264"
                  image2x="https://via.placeholder.com/492x528"
                  imageWidth="246"
                  imageHeight="264"
                  brand="Third Brand"
                  name="Third Product"
                  isDay
                  price="40"
                  subPrice="30"
                  description="This cleanser offers a great cleansing ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacinia urna eu lectus consequat vehicula. Mauris eu elit eu sapien ullamcorper congue."
                  instructions="Apply 1 pump massaging inwards around the eye area and up along the brow-bone, and on the top of the cheekbone"
                />
                <Product
                  image="https://via.placeholder.com/246x264"
                  image2x="https://via.placeholder.com/492x528"
                  imageWidth="246"
                  imageHeight="264"
                  brand="Fourth Brand"
                  name="Fourth Product"
                  isDay
                  price="40"
                  subPrice="30"
                  description="This cleanser offers a great cleansing ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacinia urna eu lectus consequat vehicula. Mauris eu elit eu sapien ullamcorper congue."
                  instructions="Apply 1 pump massaging inwards around the eye area and up along the brow-bone, and on the top of the cheekbone"
                />
              </Step>
            </div>

            <div v-else class="regimen-content__inner">
              <Step
                title="Cleanse"
                description="This is the night version of the Regimen."
              >
                <Product
                  image="https://via.placeholder.com/246x264"
                  image2x="https://via.placeholder.com/492x528"
                  imageWidth="246"
                  imageHeight="264"
                  brand="Test Brand"
                  name="Product Name"
                  isNight
                  price="30"
                  subPrice="20"
                  description="This cleanser offers a great cleansing ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacinia urna eu lectus consequat vehicula. Mauris eu elit eu sapien ullamcorper congue."
                  instructions="Apply 1 pump massaging inwards around the eye area and up along the brow-bone, and on the top of the cheekbone"
                  inRegimen
                />
              </Step>
              <Step
                title="Tone"
                description="Follow with a toner to balance the skin’s pH and prepare for better absorption of serum ingredients."
              >
                <Product
                  image="https://via.placeholder.com/246x264"
                  image2x="https://via.placeholder.com/492x528"
                  imageWidth="246"
                  imageHeight="264"
                  brand="Second Brand"
                  name="Second Product"
                  isDay
                  price="40"
                  subPrice="30"
                  description="This cleanser offers a great cleansing ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacinia urna eu lectus consequat vehicula. Mauris eu elit eu sapien ullamcorper congue."
                  instructions="Apply 1 pump massaging inwards around the eye area and up along the brow-bone, and on the top of the cheekbone"
                />
              </Step>
              <Step
                title="Treat"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel arcu laoreet, viverra neque in, malesuada nunc"
              >
                <Product
                  image="https://via.placeholder.com/246x264"
                  image2x="https://via.placeholder.com/492x528"
                  imageWidth="246"
                  imageHeight="264"
                  brand="Third Brand"
                  name="Third Product"
                  isNight
                  price="40"
                  subPrice="30"
                  description="This cleanser offers a great cleansing ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacinia urna eu lectus consequat vehicula. Mauris eu elit eu sapien ullamcorper congue."
                  instructions="Apply 1 pump massaging inwards around the eye area and up along the brow-bone, and on the top of the cheekbone"
                />
                <Product
                  image="https://via.placeholder.com/246x264"
                  image2x="https://via.placeholder.com/492x528"
                  imageWidth="246"
                  imageHeight="264"
                  brand="Fourth Brand"
                  name="Fourth Product"
                  isNight
                  price="40"
                  subPrice="30"
                  description="This cleanser offers a great cleansing ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacinia urna eu lectus consequat vehicula. Mauris eu elit eu sapien ullamcorper congue."
                  instructions="Apply 1 pump massaging inwards around the eye area and up along the brow-bone, and on the top of the cheekbone"
                />
              </Step>
            </div>
          </div>

          <Sidebar :prices="prices" :salePrices="salePrices" v-model="frequency" />
        </div>
      </div>

      <Build :prices="prices" :salePrices="salePrices" v-model="frequency" />
    </section>
  </div>
</template>

<script>
import metaMixin from "@/mixins/metaMixin";

import SlideUpDown from "vue-slide-up-down";

import LazyImage from "@/components/global/LazyImage";
import SvgIcon from "@/components/global/SvgIcon";
import Button from "@/components/global/Button";
import Step from "@/components/pages/regimen/Step";
import Product from "@/components/pages/regimen/Product";
import Sidebar from "@/components/pages/regimen/Sidebar";
import Build from "@/components/pages/regimen/Build";

export default {
  name: "Regimen",

  mixins: [metaMixin],

  components: {
    LazyImage,
    SvgIcon,
    Button,
    SlideUpDown,
    Step,
    Product,
    Sidebar,
    Build
  },

  data() {
    return {
      firstName: "Jess",
      frequency: {
        selected: "once",
        subscriptionFrequency: null
      },
      prices: {
        once: 677,
        subscription: 577
      },
      salePrices: {
        once: null,
        subscription: null
      },
      applyMode: false,
      isDay: true
    };
  },

  methods: {
    printTrigger: function() {
      window.print();
    },
    modeToggle: function(val) {
      this.applyMode = val;
    }
  },

  asyncData({ app, params, error }) {
    app.store.dispatch("setMetaInfo", {
      title: "Regimen",
      $liveChat: app.$liveChat
    });
  }
};
</script>
