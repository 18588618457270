<template>
  <div class="beauty-marks">
    <div class="row">
      <div class="beauty-marks__main column small-12 large-9">
        <section>
          <div class="row">
            <div class="column small-12 xxlarge-10 xxxlarge-9">
              <h1>{{ page.title | getTitle }}</h1>

              <div class="beauty-marks__body wysiwyg">
                <prismic-rich-text :field="page.description" />

                <PrismicLazyImage
                  v-if="page.featured_image"
                  :image="page.featured_image"
                  width="1000"
                  :disableLazy="true"
                />

                <div class="beauty-marks__body__section">
                  <h2>How It Works</h2>

                  <prismic-rich-text :field="page.how_it_works" />
                </div>

                <div v-if="page.other_section_heading || page.other_section_content" class="beauty-marks__body__section">
                  <h2 v-if="page.other_section_heading">{{ page.other_section_heading }}</h2>

                  <prismic-rich-text v-if="page.other_section_content" :field="page.other_section_content" />
                </div>
              </div>

              <div
                v-if="waysToEarn && waysToEarn.length"
                class="beauty-marks__ways-to-earn"
              >
                <h2>Ways To Earn Points</h2>

                <div class="row gutter-small">
                  <div
                    v-for="(item, i) in waysToEarn"
                    :key="i"
                    class="column small-12 large-6 xlarge-5"
                  >
                    <span v-if="item.num_points" class="h3">
                      {{ item.num_points }} Point{{
                        item.num_points > 1 ? 's' : ''
                      }}
                    </span>
                    <p v-if="item.how_to_earn">{{ item.how_to_earn }}</p>
                  </div>
                </div>
              </div>

              <RewardsList :rewards="rewards" />
            </div>
          </div>
        </section>
      </div>

      <aside
        class="beauty-marks__sidebar column small-12 large-3"
        ref="stickyParent"
      >
        <div class="sticky" ref="stickyEl">
          <div v-show="isLoggedIn">
            <span class="h4">Your Points</span>
            <span class="points">{{ numPoints }}</span>

            <Button
              to="/account/beauty-marks"
              text="Start Redeeming"
              size="small"
              :isFullWidth="true"
            />
          </div>

          <div v-show="!isLoggedIn">
            <Button
              @click="openHeaderMenuExpand('register')"
              text="Register"
              size="small"
              :isFullWidth="true"
            />

            <Button
              @click="openHeaderMenuExpand('login')"
              text="Log In"
              size="small"
              :isFullWidth="true"
              :isHollow="true"
            />

            <ArrowLink
              v-if="page.terms_conditions_link"
              :link="page.terms_conditions_link"
              text="View Terms and Conditions"
            />
          </div>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex'

  import { fetchBeautyMarksData } from '@/prismic/queries'

  import stickyMixin from '@/mixins/stickyMixin'
  import metaMixin from '@/mixins/metaMixin'

  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import RewardsList from '@/components/global/RewardsList'
  import Button from '@/components/global/Button'
  import ArrowLink from '@/components/global/ArrowLink'

  export default {
    name: 'BeautyMarks',

    hideFollow: true,

    mixins: [stickyMixin, metaMixin],

    components: {
      PrismicLazyImage,
      RewardsList,
      Button,
      ArrowLink,
    },

    data() {
      return {
        page: null,
        rewards: [],
      }
    },

    computed: {
      ...mapState({
        numPoints: state => state.smile?.points_balance,
        locale: state => state.locale,
      }),

      ...mapGetters(['isLoggedIn']),

      waysToEarn() {
        return this.locale == 'en-ca'
          ? this.page.ways_to_earn_ca
          : this.page.ways_to_earn_us
      },
    },

    methods: {
      ...mapActions(['openHeaderMenuExpand']),
    },

    async mounted() {
      this.rewards = await this.$smile.fetchRewards()
    },

    async asyncData({ app, error }) {
      try {
        const { data } = await app.apolloProvider.defaultClient.query({
          query: fetchBeautyMarksData,
        })

        const response = data.allBeauty_markss.edges

        if (response.length) {
          const page = response[0].node

          app.store.dispatch('setMetaInfo', {
            meta: page,
            title: 'Beauty Marks',
            description: page.description,
            image: page.featured_image,
            $liveChat: app.$liveChat
          })

          return { page }
        } else {
          error({ statusCode: 404, message: 'This page could not be found' })
        }
      } catch (err) {
        console.log(err)
        error({ statusCode: 500, message: 'An error has occured' })
      }
    },
  }
</script>
