import gql from 'graphql-tag'

import {
  UrlFragment,
  ArticleCardFragment,
  ProductCardFragment,
} from '@/prismic/fragments'

export const fetchPageByUID = gql`
  query ($uid: String!) {
    allPages(uid: $uid) {
      edges {
        node {
          title
          featured_image
          content
          content_us
          body {
            __typename
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
`

export const fetchStudioByUID = gql`
  query ($uid: String!) {
    allStudios(uid: $uid) {
      edges {
        node {
          title
          description
          services_description
          featured_image
          address_line_1
          address_line_2
          google_maps_url
          phone_number
          text_phone_number
          email
          hours {
            days
            start_time
            end_time
          }
          services {
            service_title
            service_description
            service_length
            service_price
            service_category {
              ... on Service_category {
                title
                _meta {
                  uid
                }
              }
            }
          }
          content
          body {
            __typename
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
`

export const fetchServiceCategoryByUID = gql`
  query ($uid: String!) {
    allService_categorys(uid: $uid) {
      edges {
        node {
          title
          featured_image
          _meta {
            uid
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
`

export const fetchAboutData = gql`
  query {
    allAbouts {
      edges {
        node {
          title
          subtitle
          image_1
          image_2
          quote
          image_3
          image_4
          main_section_content
          main_section_link {
            ...UrlFragment
          }
          gee_section_heading
          studio_section_heading
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
  ${UrlFragment}
`

export const fetchAllGees = gql`
  query {
    allAuthors(where: { is_gee: true }) {
      edges {
        node {
          name
          _meta {
            uid
          }
          featured_image
          is_gee
          role
          description
          quote
          twitter_handle
          instagram_handle
          learn_more_link {
            ...UrlFragment
          }
        }
      }
    }
  }
  ${UrlFragment}
`

export const fetchArticleByUID = gql`
  query ($uid: String!) {
    allArticles(uid: $uid, where: { hide_article: false }) {
      edges {
        node {
          title
          _meta {
            uid
            id
            firstPublicationDate
          }
          published_date
          featured_image
          is_featured_image_full_width
          featured_video
          category {
            ... on Blog_category {
              title
              _meta {
                uid
                id
              }
            }
          }
          author {
            ... on Author {
              name
              _meta {
                uid
              }
              featured_image
              description
              twitter_handle
              instagram_handle
              learn_more_link {
                ...UrlFragment
              }
            }
          }
          disable_gate
          content
          body {
            ... on ArticleBodySide_widget {
              fields {
                canadian_variant_associated_sku
                us_variant_associated_sku
                product {
                  ...ProductCardFragment
                }
              }
            }
            ... on ArticleBodyProduct_widget {
              fields {
                canadian_variant_associated_sku
                us_variant_associated_sku
                product {
                  ...ProductCardFragment
                }
              }
            }
          }
          signoff
        }
      }
    }
  }
  ${UrlFragment}
  ${ProductCardFragment}
`

export const fetchRelatedArticles = gql`
  query ($categoryId: String!) {
    allArticles(
      first: 3
      where: { category: $categoryId, hide_article: false }
      sortBy: published_date_DESC
    ) {
      edges {
        node {
          ...ArticleCardFragment
        }
      }
    }
  }
  ${ArticleCardFragment}
`

export const fetchCustomFeatureLandingByUID = gql`
  query ($uid: String!) {
    allCustom_feature_landings(uid: $uid) {
      edges {
        node {
          title
          _meta {
            uid
          }
          show_hero
          hero_slides {
            background_image
            mobile_background_image
            title
            subtitle
            description
            button_link {
              ...UrlFragment
            }
            button_text
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
  ${UrlFragment}
`

export const fetchHomepageData = gql`
  query {
    allHomepages {
      edges {
        node {
          show_hero
          hero_slides {
            background_image
            mobile_background_image
            title
            subtitle
            description
            button_link {
              ...UrlFragment
            }
            button_text
          }
          gee_makeup_description
          skincare_description
          gee_studios_description
          meta_title
          meta_description
          meta_keywords
          meta_image
          body1 {
            ... on HomepageBody1Homepage_feature {
              primary {
                description
                large_image
              }
            }
          }
        }
      }
    }
  }
  ${UrlFragment}
`

export const fetchAboutTheGees = gql`
  query {
    allGlobals {
      edges {
        node {
          about_the_gees_title
          about_the_gees_subtitle
          about_the_gees_description
          about_the_gees_link {
            ...UrlFragment
          }
          about_the_gees_image_1
          about_the_gees_image_2
          about_the_gees_image_3
          about_the_gees_image_4
        }
      }
    }
  }
  ${UrlFragment}
`

export const fetchGeePicks = gql`
  query {
    allAuthors(where: { is_gee: true }) {
      edges {
        node {
          name
          _meta {
            uid
          }
          featured_image
          picks {
            ... on Collection {
              products {
                product {
                  ...ProductCardFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchArticlesByUIDs = gql`
  query ($uids: [String!]!) {
    allArticles(
      uid_in: $uids
      where: { hide_article: false }
      sortBy: published_date_DESC
    ) {
      edges {
        node {
          ...ArticleCardFragment
        }
      }
    }
  }
  ${ArticleCardFragment}
`

export const fetchArticlesAndConcernsByUIDs = gql`
  query ($uids: [String!]!) {
    allArticles(
      uid_in: $uids
      where: { hide_article: false }
      sortBy: published_date_DESC
    ) {
      edges {
        node {
          title
          _meta {
            uid
            id
            type
          }
          featured_image
          is_video_article
        }
      }
    }
    allConcerns(uid_in: $uids) {
      edges {
        node {
          title
          _meta {
            uid
            id
            type
          }
          featured_image
        }
      }
    }
  }
`

export const fetchQuizCallout = gql`
  query {
    allGlobals {
      edges {
        node {
          quiz_callout_title
          quiz_callout_subtitle
          quiz_callout_description
          quiz_callout_button_text
          quiz_callout_image
        }
      }
    }
  }
`

export const fetchQuestionsCallout = gql`
  query {
    allGlobals {
      edges {
        node {
          questions_callout_title
          questions_callout_subtitle
          questions_callout_description
          questions_callout_button_text
          questions_callout_image
        }
      }
    }
  }
`

export const fetchBrandLandingData = gql`
  query {
    allBrand_landings {
      edges {
        node {
          description
          featured_image
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
`

export const fetchCustomBrandLandingData = gql`
  query ($uid: String!) {
    allCustom_brand_landings(uid: $uid) {
      edges {
        node {
          title
          _meta {
            uid
          }
          description
          featured_image
          brands {
            brand {
              ... on Brand {
                title
                _meta {
                  uid
                  id
                  type
                }
                description
              }
            }
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
`

export const fetchConcernLandingData = gql`
  query {
    allConcern_landings {
      edges {
        node {
          description
          featured_image
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
`

export const fetchConcernByUID = gql`
  query ($uid: String!) {
    allConcerns(uid: $uid) {
      edges {
        node {
          title
          _meta {
            uid
          }
          description
          featured_image
          subtitle
          content
          more_content
          features {
            feature {
              ... on Article {
                title
                _meta {
                  uid
                  type
                }
                featured_image
                content
              }
              ... on Product_type {
                title
                _meta {
                  uid
                  type
                }
                shopCategory {
                  ... on Shop_category {
                    _meta {
                      uid
                    }
                  }
                }
                featured_image
                description
              }
              ... on Collection {
                title
                _meta {
                  uid
                  type
                }
                featured_image
                description
              }
            }
          }
          callout
          callout_collection {
            ... on Collection {
              title
              _meta {
                uid
                type
              }
              featured_image
              description
            }
          }
          featured_products {
            product {
              ...ProductCardFragment
            }
          }
          featured_products_description
          featured_collections {
            collection {
              ... on Collection {
                title
                _meta {
                  uid
                }
                description
              }
            }
          }
          similar_concerns {
            concern {
              ... on Concern {
                title
                _meta {
                  uid
                }
                description
              }
            }
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchCustomLandingByUID = gql`
  query ($uid: String!) {
    allCustom_landings(uid: $uid) {
      edges {
        node {
          title
          _meta {
            uid
          }
          description
          featured_image
          subtitle
          content
          more_content
          features {
            feature {
              ... on Article {
                title
                _meta {
                  uid
                  type
                }
                featured_image
                content
              }
              ... on Product_type {
                title
                _meta {
                  uid
                  type
                }
                shopCategory {
                  ... on Shop_category {
                    _meta {
                      uid
                    }
                  }
                }
                featured_image
                description
              }
              ... on Collection {
                title
                _meta {
                  uid
                  type
                }
                featured_image
                description
              }
            }
          }
          callout
          callout_collection {
            ... on Collection {
              title
              _meta {
                uid
                type
              }
              featured_image
              description
            }
          }
          featured_products {
            product {
              ...ProductCardFragment
            }
          }
          featured_products_description
          featured_collections {
            collection {
              ... on Collection {
                title
                _meta {
                  uid
                }
                description
              }
            }
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchConcernsByUIDS = gql`
  query ($uids: [String!]!) {
    allConcerns(uid_in: $uids) {
      edges {
        node {
          title
          _meta {
            uid
          }
          featured_image
          content
        }
      }
    }
  }
`

export const fetchLookByUid = gql`
  query ($uid: String!) {
    allShop_the_looks(uid: $uid) {
      edges {
        node {
          title
          image
          description
          products {
            product {
              ...ProductCardFragment
            }
          }
        }
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchProductByUID_SSR = gql`
  query ($uid: String!) {
    product(uid: $uid, lang: "en-ca") {
      title
      _meta {
        uid
        id
      }
      shopify_product_ca
      shopify_product_us
      has_subscription
      is_best_seller
      is_unavailable_ca
      is_unavailable_us
      unavailable_variant_skus_ca
      unavailable_variant_skus_us
      coming_soon_variant_skus_ca
      coming_soon_variant_skus_us
      is_pre_order
      pre_order_text
      is_collage_product
      is_kit
      is_coming_soon
      show_chat_bubble_text
      size
      tag_override
      add_to_bag_override_link_ca {
        ...UrlFragment
      }
      add_to_bag_override_link_us {
        ...UrlFragment
      }
      add_to_bag_override_text_ca
      add_to_bag_override_text_us
      description
      details
      product_type {
        ... on Product_type {
          title
          _meta {
            uid
            id
          }
        }
      }
      brand {
        ... on Brand {
          title
          login_to_view_pricing_on_us
          _meta {
            uid
            id
            type
          }
        }
      }
      gallery {
        image
        canadian_variant_associated_sku
        us_variant_associated_sku
        is_product_shot
        is_swatch
      }
      video
      preview_video {
        ... on _FileLink {
          url
        }
      }
      body {
        __typename
      }
      body1 {
        __typename
      }
      is_gift_card
      is_bundle
      bundle_products {
        product {
          ...ProductCardFragment
        }
        only_visible_variant_skus_ca
        only_visible_variant_skus_us
      }
      meta_title
      meta_description
      meta_keywords
      meta_image
    }
  }
  ${UrlFragment}
  ${ProductCardFragment}
`

export const fetchProductByUID_ClientAll = gql`
  query ($uid: String!) {
    allGlobals {
      edges {
        node {
          related_products_title
          related_products_subtitle
          related_products_description
        }
      }
    }
    product(uid: $uid, lang: "en-ca") {
      product_type {
        ... on Product_type {
          title
          _meta {
            uid
            id
            type
          }
        }
      }
      is_am
      is_pm
      features {
        item
      }
      how_to_use
      key_facts_ingredients {
        item
      }
      ingredients
      related_products {
        product {
          ...ProductCardFragment
        }
      }
      related_products_title
      related_products_subtitle
      related_products_description
      quotes {
        quote
        person
        num_stars
      }
      show_about_the_gees
      e_gift_card_text
    }
  }
  ${ProductCardFragment}
`

export const fetchProductByUID_ClientShort = gql`
  query ($uid: String!) {
    product(uid: $uid, lang: "en-ca") {
      product_type {
        ... on Product_type {
          title
          _meta {
            uid
            id
            type
          }
        }
      }
      is_am
      is_pm
    }
  }
`

export const fetchProductCardByUID = gql`
  query ($uid: String!) {
    product(uid: $uid, lang: "en-ca") {
      ...ProductCardFragment
    }
  }
  ${ProductCardFragment}
`

export const fetchProductCardsByUIDs = gql`
  query ($uids: [String!]!) {
    allProducts(uid_in: $uids) {
      edges {
        node {
          ...ProductCardFragment
        }
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchRelatedProducts = gql`
  query ($brandId: String, $productTypeId: String) {
    brand: allProducts(first: 8, where: { brand: $brandId }) {
      edges {
        node {
          ...ProductCardFragment
        }
      }
    }
    productType: allProducts(
      first: 8
      where: { product_type: $productTypeId }
    ) {
      edges {
        node {
          ...ProductCardFragment
        }
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchCollectionProductsByUIDs = gql`
  query ($uids: [String!]!) {
    allCollections(uid_in: $uids) {
      edges {
        node {
          title
          featured_image
          description
          _meta {
            uid
          }
          products {
            product {
              ...ProductCardFragment
            }
          }
        }
      }
    }
  }
  ${ProductCardFragment}
`

export const fetchBlogLandingData = gql`
  query {
    allBlog_landings {
      edges {
        node {
          description
          featured_categories {
            category {
              ... on Blog_category {
                title
                _meta {
                  uid
                  type
                  id
                }
              }
            }
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
`

export const fetchAllBlogCategories = gql`
  query {
    allBlog_categorys {
      edges {
        node {
          title
          _meta {
            uid
            id
          }
        }
      }
    }
  }
`

export const fetchAllAuthors = gql`
  query {
    allAuthors {
      edges {
        node {
          name
          _meta {
            uid
            id
          }
          featured_image
        }
      }
    }
  }
`

export const fetchAllArticles = gql`
  query ($numResults: Int!, $endCursor: String) {
    allArticles(
      first: $numResults
      after: $endCursor
      sortBy: published_date_DESC
      where: { hide_article: false }
    ) {
      edges {
        node {
          ...ArticleCardFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ArticleCardFragment}
`

export const fetchArticlesByCategoryAndAuthor = gql`
  query (
    $numResults: Int!
    $endCursor: String
    $categoryId: String!
    $authorId: String!
  ) {
    allArticles(
      first: $numResults
      after: $endCursor
      where: { category: $categoryId, author: $authorId, hide_article: false }
      sortBy: published_date_DESC
    ) {
      edges {
        node {
          ...ArticleCardFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ArticleCardFragment}
`

export const fetchArticlesByCategory = gql`
  query ($numResults: Int!, $endCursor: String, $categoryId: String!) {
    allArticles(
      first: $numResults
      after: $endCursor
      where: { category: $categoryId, hide_article: false }
      sortBy: published_date_DESC
    ) {
      edges {
        node {
          ...ArticleCardFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ArticleCardFragment}
`

export const fetchArticlesByAuthor = gql`
  query ($numResults: Int!, $endCursor: String, $authorId: String!) {
    allArticles(
      first: $numResults
      after: $endCursor
      where: { author: $authorId, hide_article: false }
      sortBy: published_date_DESC
    ) {
      edges {
        node {
          ...ArticleCardFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ArticleCardFragment}
`

export const fetchBlogLandingCallout = gql`
  query {
    allBlog_landings {
      edges {
        node {
          callout
          callout_collection {
            ... on Collection {
              title
              _meta {
                uid
                type
              }
              featured_image
              description
            }
          }
        }
      }
    }
  }
`

export const fetchBlogCategoryCalloutByUID = gql`
  query ($uid: String!) {
    allBlog_categorys(uid: $uid) {
      edges {
        node {
          callout
          callout_collection {
            ... on Collection {
              title
              _meta {
                uid
                type
              }
              featured_image
              description
            }
          }
        }
      }
    }
  }
`

export const fetchAuthorCalloutByUID = gql`
  query ($uid: String!) {
    allAuthors(uid: $uid) {
      edges {
        node {
          callout
          callout_collection {
            ... on Collection {
              title
              _meta {
                uid
                type
              }
              featured_image
              description
            }
          }
        }
      }
    }
  }
`

export const fetchBeautyMarksData = gql`
  query {
    allBeauty_markss {
      edges {
        node {
          title
          description
          featured_image
          how_it_works
          other_section_heading
          other_section_content
          terms_conditions_link {
            ...UrlFragment
          }
          ways_to_earn_ca {
            num_points
            how_to_earn
          }
          ways_to_earn_us {
            num_points
            how_to_earn
          }
          rewards_products_ca {
            reward_id
            shopify_product
          }
          rewards_products_us {
            reward_id
            shopify_product
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
  ${UrlFragment}
`

export const fetchGiftCardsData = gql`
  query {
    allGift_cardss {
      edges {
        node {
          title
          _meta {
            id
          }
          shopify_product_ca
          shopify_product_us
          gallery {
            image
          }
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
`

export const fetchArticleTitleByUID = gql`
  query ($uid: String!) {
    allArticles(uid: $uid) {
      edges {
        node {
          title
          _meta {
            uid
          }
        }
      }
    }
  }
`

export const fetchHomepageCalloutById = gql`
  query ($calloutIds: [String!]!) {
    allHomepage_callouts(id_in: $calloutIds) {
      edges {
        node {
          label
          title
          cta_link {
            ...UrlFragment
          }
          cta_text
          image
        }
      }
    }
  }
  ${UrlFragment}
`
