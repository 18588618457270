<template>
  <div v-if="page" class="home">
    <h1 class="accessible">{{ $config.siteName }}</h1>

    <Hero
      v-if="page.show_hero"
      :slides="page.hero_slides"
    />

    <slice-zone
      v-if="page.body1 && page.body1.length"
      type="homepage"
      queryType="single"
      body="body1"
      class="home__slicezone" />

    <section class="home__ctas">
      <div class="row">
        <div class="home__ctas__left column small-12 xlarge-4">
          <nuxt-link :to="makeupLink"><h2 class="h3">Gee Makeup</h2></nuxt-link>
          <p v-if="page.gee_makeup_description">
            {{ page.gee_makeup_description }}
          </p>

          <ArrowLink :to="makeupLink" text="Shop All" />
          <nuxt-link :to="makeupLink" class="arrow">
            <SvgIcon name="chevron-right-light" />
          </nuxt-link>
        </div>

        <div class="home__ctas__center column small-12 xlarge-4">
          <nuxt-link :to="skincareLink"><h2 class="h3">Skincare</h2></nuxt-link>
          <p v-if="page.skincare_description">
            {{ page.skincare_description }}
          </p>

          <ArrowLink :to="skincareLink" text="Shop All" />
          <nuxt-link :to="skincareLink" class="arrow">
            <SvgIcon name="chevron-right-light" />
          </nuxt-link>
        </div>

        <div class="home__ctas__right column small-12 xlarge-4">
          <nuxt-link :to="studioLink">
            <h2 class="h3">Gee Studios</h2>
          </nuxt-link>
          <p v-if="page.gee_studios_description">
            {{ page.gee_studios_description }}
          </p>

          <ArrowLink :to="studioLink" text="Learn More" />
          <nuxt-link :to="studioLink" class="arrow">
            <SvgIcon name="chevron-right-light" />
          </nuxt-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import SliceZone from 'vue-slicezone'

  import { fetchHomepageData } from '@/prismic/queries'

  import metaMixin from '@/mixins/metaMixin'

  import Hero from '@/components/pages/home/Hero'
  import ArrowLink from '@/components/global/ArrowLink'
  import SvgIcon from '@/components/global/SvgIcon'

  export default {
    name: 'Home',

    mixins: [metaMixin],

    components: {
      Hero,
      SliceZone,
      ArrowLink,
      SvgIcon,
    },

    data() {
      return {
        page: null,
      }
    },

    computed: {
      ...mapGetters(['localStudio']),
      makeupLink() {
        return '/shop/makeup'
      },
      skincareLink() {
        return '/shop/skincare'
      },
      studioLink() {
        return `/studio/${this.localStudio && this.localStudio._meta.uid}`
      },
    },

    mounted() {
      this.$pinterest.pageVisit()
    },

    async asyncData({ app, store, error }) {
      try {
        const { data } = await app.apolloProvider.defaultClient.query({
          query: fetchHomepageData,
        })

        const response = data.allHomepages.edges

        if (!response.length) {
          error({ statusCode: 404, message: 'This page could not be found' })
          return false
        }

        const page = response[0].node

        app.store.dispatch('setMetaInfo', {
          meta: page,
          description: store.state.globalData.site_tagline,
          $liveChat: app.$liveChat,
        })

        return { page }
      } catch (err) {
        console.log(err)
        error({ statusCode: 500, message: 'An error has occured' })
      }
    },

    jsonld() {
      return {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        name: this.$config.siteName,
        url: this.url,
        image: this.metaImage,
        description: this.metaDescription,
      }
    },
  }
</script>
